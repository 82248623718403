/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ProjectList from "../Components/Project/ProjectList";
import MapComponent from "../Components/Map/DefaultMap";
import { useProjectsContext } from "../Context/ProjectContext";
import { fetchProjects } from "../utils/carto";

import { BeatLoader } from "react-spinners";

const HomePageLayout = ({ langData }) => {
  const { updateProjects } = useProjectsContext();
  const [cartoData, setCartoData] = useState();
  const [loading, setLoading] = useState(true); // Add loading state

  async function setProjectsData() {
    const data = await fetchProjects();
    let cartoGeoJSON;
    updateProjects(data);
    if (data) {
      cartoGeoJSON = {
        type: "FeatureCollection",
        data: data,
        features: data?.map((feature) => ({
          type: "Feature",
          properties: {
            fillColor: "red",
            id: feature.cartodb_id,
            name: feature.name,
          },
          geometry: {
            type: "Point",
            coordinates: [feature.lon, feature.lat],
          },
        })),
      };
    }
    setCartoData(cartoGeoJSON);
    setLoading(false);
  }

  useEffect(() => {
    setProjectsData();
  }, []);

  return (
    <div style={{ display: "flex", height: "calc(100vh - 64px)" }}>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <BeatLoader color="#007BFF" />
        </div>
      ) : (
        // Inside HomePageLayout component return block
        <>
          <div
            style={{
              flex: "0 0 17%",
              backgroundColor: "#f0f0f0",
           
            }}
          >
            <ProjectList langData={langData} />
          </div>

          <div style={{ flex: "1", position: "relative", overflow: "hidden" }}>
            <MapComponent cartoData={cartoData} />
          </div>
        </>
      )}
    </div>
  );
};

export default HomePageLayout;
