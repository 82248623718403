import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const AddProjectButton = ({langData}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to="/create"
      style={{
        marginTop: '10px',
        borderRadius: '20px',
        width: 'fit-content',
        padding: '8px 16px',
      }}
    >
      <AddIcon style={{ marginRight: '8px' }} />
      {langData.addProject}
    </Button>
  );
};

export default AddProjectButton;
