/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  ListItem,
  Tooltip,
  ListItemText,
  ListItemButton,
  Slide,
  Divider,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import "./ProjectList.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { useProjectsContext } from "../../Context/ProjectContext";
import { useNavigate } from "react-router-dom";
import AddProjectButton from "../common/AddButton";
import { deleteProject } from "../../utils/carto";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectsData, receiveProjectData } from "../../redux/actions";
import io from "socket.io-client"; // Import the socket.io-client library

// import DonutChart from '../common/DonutChartProgress';
import DonutChart from "../common/DonutChart";

const ProjectList = ({langData}) => {
  const { data } = useSelector((state) => state.projects.receivedData) || {};
  const { updateProjects } = useProjectsContext();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fndProject, setFoundProject] = useState("");
  const projects = useSelector((state) => state.projects.projects);

  const dispatch = useDispatch();

  const [tooltipContent, setTooltipContent] = useState(null); // State for tooltip content
  const [showTooltip, setShowTooltip] = useState(false); // State to control tooltip visibility
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [fail, setFail] = useState(0);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
    socket.on("jobStatus", (data) => {
      // console.log('Received job status:', data);

      // Extract the projectId from the received message payload
      const receivedProjectId = data.projectId;

      // Find the project from the list using the received projectId
      const foundProject = projects.find(
        (project) => project.cartodb_id === receivedProjectId
      );
      dispatch(
        receiveProjectData({ projectId: receivedProjectId, data: data })
      );

      if (foundProject) {
        // Set the tooltip content based on the received message
        const tooltip = `Status: ${data.status}, Message: ${data.message}`;
        setTooltipContent(tooltip);
        setFoundProject(foundProject);
        setProgress(data.completed);
        setFail(data.failureCnt);
        setTotal(data.total);

        // Show the tooltip
        setShowTooltip(true);

        // Use setTimeout to hide the tooltip after a certain duration (e.g., 5 seconds)
        setTimeout(() => {
          setShowTooltip(false);
          setTooltipContent(null);
        }, 50000);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [projects, fndProject, showTooltip, tooltipContent]);

  useEffect(() => {
    dispatch(fetchProjectsData());
  }, [dispatch]);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
  };

  const filteredProjects = projects?.filter((project) =>
    project.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleDoubleClick = (projectId) => {
    updateProjects(projectId);
  };

  const handleDeleteClick = (project, event) => {
    event.stopPropagation();
    setProjectToDelete(project);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (projectToDelete) {
      try {
        setLoading(true);
        await deleteProject(projectToDelete.cartodb_id);
        dispatch(fetchProjectsData());
      } catch (error) {
        console.error("Error deleting project:", error);
      } finally {
        setLoading(false);
        setDeleteDialogOpen(false);
        setProjectToDelete(null);
        navigate("/");
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProjectToDelete(null);
  };

  return (
    <Grid container direction="column" spacing={2} style={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <AddProjectButton langData ={langData} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <TextField
          label= {langData.searchProjects}
          variant="outlined"
          fullWidth
          value={searchInput}
          onChange={handleSearchInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchInput && (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchInput("")} size="small">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{ padding: "10px" }} // Add this line to add padding
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} className="custom-scrollbar">
        {filteredProjects?.length > 0 ? (
          filteredProjects?.map((project, index) => (
            <React.Fragment key={project.cartodb_id}>
              <ListItem
                onClick={() => handleDoubleClick(project)}
                disablePadding
                style={{
                  borderBottom:
                    index !== filteredProjects.length - 1
                      ? "1px solid #ccc"
                      : "none",
                }}
              >
                <ListItemButton
                  component={Link}
                  to={`/edit/${project.cartodb_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <ListItemText primary={project.name} />
                </ListItemButton>
                {project.cartodb_id === data?.projectId && total !== 0 && progress !== 0 && (
        <React.Fragment>
          <DonutChart progress={progress} fail={fail} total={total} />
          <Typography>{`${progress}/${total}`}</Typography>
        </React.Fragment>
      )}
                <IconButton
                  color="red"
                  onClick={(e) => handleDeleteClick(project, e)}
                  style={{ transition: "all 0.3s" }}
                >
                 <Tooltip title={langData.delete}>
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </ListItem>
            </React.Fragment>
          ))
        ) : (
          <Grid item xs={12}>
            <p>No projects available.</p>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        PaperProps={{
          style: {
            transform: "translateY(0)",
            transition: "transform 0.3s",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle>{langData.confirmDelete}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the project{" "}
            <span style={{ fontWeight: "bold", color: "red" }}>
              {projectToDelete?.name}
            </span>
            ?
          </DialogContentText>
          {loading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            {langData.cancel}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="secondary"
            disabled={loading}
            style={{ transition: "background-color 0.3s" }}
          >
            {langData.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ProjectList;
