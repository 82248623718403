// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom scrollbar */
::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; /* Change the track color if needed */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #c2c2c2; /* Change the handle color */
    border-radius: 10px; /* Adjust the border radius for a rounder handle */
  }
  `, "",{"version":3,"sources":["webpack://./src/resources/customscroller.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,UAAU,EAAE,+BAA+B;EAC7C;;EAEA,UAAU;EACV;IACE,uBAAuB,EAAE,qCAAqC;EAChE;;EAEA,WAAW;EACX;IACE,yBAAyB,EAAE,4BAA4B;IACvD,mBAAmB,EAAE,kDAAkD;EACzE","sourcesContent":["/* Custom scrollbar */\r\n::-webkit-scrollbar {\r\n    width: 5px; /* Adjust the width as needed */\r\n  }\r\n  \r\n  /* Track */\r\n  ::-webkit-scrollbar-track {\r\n    background: transparent; /* Change the track color if needed */\r\n  }\r\n  \r\n  /* Handle */\r\n  ::-webkit-scrollbar-thumb {\r\n    background-color: #c2c2c2; /* Change the handle color */\r\n    border-radius: 10px; /* Adjust the border radius for a rounder handle */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
