import React from 'react';
import { Container, Typography } from '@mui/material';

function Documentation() {
  return (
    <Container maxWidth="sm" style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Documentation Coming Soon
      </Typography>
      <Typography variant="body1">
        We're working hard on the documentation. Check back soon!
      </Typography>
    </Container>
  );
}

export default Documentation;
