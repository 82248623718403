import L from 'leaflet';

class NonSelectableLayer extends L.Layer {
    constructor(latlngs, options) {
      super(options);
      this.polygon = L.polygon(latlngs, options);
    }

    addTo(map) {
      this._map = map;
      this.polygon.addTo(map);
      this._initInteractions();
      return this;
    }

    _initInteractions() {
      this.polygon.on('mousedown', (e) => {
        // Prevent the default behavior (selection) on mousedown
        L.DomEvent.preventDefault(e);
      });
    }

    setStyle(style) {
      this.polygon.setStyle(style);
    }
  }


export default NonSelectableLayer