

export const projectsReducer = (state = {
  projects: [],
  receivedData: null, // Add a field to store received data
}, action) => {
  switch (action.type) {
    case 'FETCH_PROJECTS_SUCCESS':
      return { ...state, projects: action.payload };
    case 'RECEIVE_PROJECT_DATA':
      return { ...state, receivedData: action.payload };
    default:
      return state;
  }
};

// projectReducer.js

// projectReducer.js

export const polydataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_AOI_POLYDATA':
      return { ...state, aoiPolyData: action.payload };
    case 'SET_PLAN1_POLYDATA':
      return { ...state, plan1PolyData: action.payload };
    case 'SET_PLAN2_POLYDATA':
      return { ...state, plan2PolyData: action.payload };
    case 'SET_PLAN3_POLYDATA':
      return { ...state, plan3PolyData: action.payload };
    case 'SET_AOI_SUM':
      return { ...state, aoisum: action.payload };
    case 'SET_PLAN1_SUM':
      return { ...state, plan1sum: action.payload };
    case 'SET_PLAN2_SUM':
      return { ...state, plan2sum: action.payload };
    case 'SET_PLAN3_SUM':
      return { ...state, plan3sum: action.payload };
    case 'SET_GEOTIFF_DATA':
      return { ...state, geotiffData: action.payload }; // Add this case to handle geotiff data
    default:
      return state;
  }
};



export const basemapReducer = (state = 'streets-v11', action) => {
    switch (action.type) {
      case 'SET_SELECTED_BASEMAP':
        return action.payload;
      default:
        return state;
    }
  };
  
  
  export const dataChangedReducer = (state = true, action) => {
    switch (action.type) {
      case 'SET_DATA_CHANGED':
        return action.payload;
      default:
        return state;
    }
  };