/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';


mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN;

const MapComponent = ({ cartoData }) => {
  const navigate = useNavigate();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null); // Reference to the map instance
  const popupRef = useRef(new mapboxgl.Popup({ closeButton: false, closeOnClick: false })); // Reference to the popup
 
  const center = [139.6917, 35.6895]; // Centered on Japan

  const selectedBasemap = useSelector((state) => state.selectedBasemap);

  // Initialize the map instance only once
  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: `mapbox://styles/mapbox/${selectedBasemap}`,
        center: center,
        zoom: 11,
      });

      const navControl = new mapboxgl.NavigationControl();
      mapRef.current.addControl(navControl, 'top-left');

      mapRef.current.on('load', () => {
        // The map style has finished loading
        if (cartoData) {
          addCartoLayerToMap(mapRef.current, cartoData);
        }
      });

      return () => {
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
      };
    }
  }, [selectedBasemap, cartoData]);

  const addCartoLayerToMap = (map, data) => {
    if (map.isStyleLoaded()) {
      if (map.getSource('carto-data')) {
        map.removeLayer('carto-layer');
        map.removeSource('carto-data');
      }

      map.addSource('carto-data', {
        type: 'geojson',
        data: data,
      });

      map.addLayer({
        id: 'carto-layer',
        type: 'circle',
        source: 'carto-data',
        paint: {
          'circle-color': ['get', 'fillColor'],
          'circle-radius': 7,
          'circle-opacity': 0.8,
        },
      });

      // map.on('click', 'carto-layer', (e) => {
      //   const { id } = e.features[0].properties;
        
      //   navigate(`/edit/${id}`);
      // });

      // Add event listeners for popups
      map.on('mouseenter', 'carto-layer', (e) => {
        const { name } = e.features[0].properties;
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const popupContent = `<div>${name}</div>`;

        popupRef.current
          .setLngLat(coordinates)
          .setHTML(popupContent)
          .addTo(map);
      });

      // Cleanup the popup when the mouse leaves the feature
      map.on('mouseleave', 'carto-layer', () => {
        popupRef.current.remove();
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
        <div ref={mapContainerRef} style={{ flex: '1', position: 'relative', overflow: 'hidden' }}></div>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1,
            backgroundColor: '#fff',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '5px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default MapComponent;
