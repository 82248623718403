/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { Snackbar, SnackbarContent } from "@mui/material";
import mapboxgl from "mapbox-gl";
import PolygonDialog from "../common/PolygonColorDialog";
import NonSelectableLayer from "../../utils/customclass";
import { useSelector, useDispatch } from "react-redux";
import { PropagateLoader } from "react-spinners";
import CircularProgress from "@mui/material/CircularProgress";
import { setDataChanged } from "../../redux/actions";
import { getFillColor } from "../../utils/functions";
import {
 
  savePlanPolygonData,
  updatePlan,
  deletePlanPolygonData,
  fetchTileLayer,
} from "../../utils/carto";

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN;

const PlanMapComponent = ({ selectedTab, project, planData, langData }) => {
  const geotiffData = useSelector((state) => state.polydata.geotiffData);
  let tiffData;
  if (geotiffData && selectedTab) {
    tiffData = geotiffData[selectedTab];
  }

  // console.log('tiff', tiffData)

  const dispatch = useDispatch();

  const mapContainerRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [map, setMap] = useState(null);
  const [overlay, setOverlay] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [render, setRender] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState(null);

  const selectedBasemap = useSelector((state) => state.selectedBasemap);
  useEffect(() => {
    try {
      const center = [35.68334155120243, 139.7670395118941];
      const map = L.map(mapContainerRef.current).setView(center, 7);

      L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/${selectedBasemap}/tiles/{z}/{x}/{y}?access_token=${mapboxgl.accessToken}`,
        {
          maxZoom: 19,
          attribution:
            '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> contributors',
        }
      ).addTo(map);

      setMap(map);

      const handleResize = () => {
       
        if (map) {
          map.invalidateSize();
        }
      };

      const observer = new ResizeObserver(handleResize);
      observer.observe(mapContainerRef.current);

      const addPolygonsToMap = (polygonData) => {
        if (polygonData && polygonData.rows) {
          polygonData.rows.forEach((row) => {
            const coordinates = row.geom.coordinates[0];
            const latLngs = coordinates.map(([lng, lat]) => [lat, lng]);
            let fillColor = "#FF00FF"; // Default pink color

            // Set the fillColor based on the type property
            switch (row.type) {
              case "treecover":
                fillColor = "#00FF00"; // Green
                break;
              case "openwater":
                fillColor = "#0000FF"; // Blue
                break;
              case "grassland":
                fillColor = "#FF0000"; // Red
                break;
              default:
                break;
            }

            const polygonStyle = {
              fillColor,
              color: fillColor,
              weight: 2,
            };

            const planPolygon = L.polygon(latLngs, polygonStyle).addTo(map);
            drawnItems.addLayer(planPolygon);

            planPolygon.project_id = row.project_id;
            planPolygon.plan_id = row.plan_id;
            planPolygon.id = row.id;
            planPolygon.type = row.type;
            planPolygon.landcover_type = row.landcover_type;
            planPolygon.crown_width = row.crown_width;

            planPolygon.on("click", () => {
              setSelectedPolygon(planPolygon);
            });
          });
        }
      };

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
          edit: true,
          remove: true,
        },
        draw: {
          polygon: true,
          polyline: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          marker: false,
        },
      });
      if (project && project.geom) {
        map.addControl(drawControl);
      }

      if (project.geom) {
        // Check if project.geom is a string before processing it
        if (typeof project.geom === "string") {
          const coordinates = project.geom
            .replace("POLYGON((", "")
            .replace("))", "")
            .split(",")
            .map((pair) => {
              const [lng, lat] = pair.trim().split(" ").map(parseFloat);
              return [lat, lng];
            });

          if (coordinates.length > 0) {
            const polygonStyle = {
              fillColor: "#FF00FF", // Pink fill color
              color: "#FF00FF", // Pink outline color
              weight: 2, // Outline width
            };

            const aoiPolygon = new NonSelectableLayer(
              coordinates,
              polygonStyle
            );

            aoiPolygon.addTo(map);
            map.fitBounds(aoiPolygon.polygon.getBounds(), {
              padding: [20, 20],
            });
            const currentZoom = map.getZoom();
            const newZoom = currentZoom - 1;
            map.setZoom(newZoom);
          }
        } else if (project.geom) {
          const coordinates = project.geom.coordinates[0].map(
            ([lng, lat]) => [lat, lng]
          );

          if (coordinates.length > 0) {
            const polygonStyle = {
              fillColor: "#FF00FF", // Pink fill color
              color: "#FF00FF", // Pink outline color
              weight: 2, // Outline width
            };
            const aoiPolygon = new NonSelectableLayer(
              coordinates,
              polygonStyle
            );

            aoiPolygon.addTo(map);
            map.fitBounds(aoiPolygon.polygon.getBounds(), {
              padding: [20, 20],
            });
            const currentZoom = map.getZoom();
            const newZoom = currentZoom - 1;
            map.setZoom(newZoom);
          } else {
            console.error(
              "No valid coordinates found in project.geom:",
              project.geom
            );
          }
        } else {
          console.error("Invalid project.geom:", project.geom);
        }
      }

      map.on("draw:created", (e) => {
        const layer = e.layer;
        drawnItems.addLayer(layer);

        setSelectedPolygon(layer);
        setIsDialogOpen(true);
      });

      map.on("draw:edited", async (e) => {
        const layers = e.layers;
        setIsLoading(true); // Show loader while making the request

        for (const layer of layers.getLayers()) {
          const polygonData = {
            geom: layer.toGeoJSON().geometry,
          };
          try {
            const response = await updatePlan(layer, polygonData);

            if (response.status === 200) {
              // Request successful, show success snackbar
              setSuccessSnackbar(true);
              dispatch(setDataChanged(true));
              setSnackbarMessage("Update successful!");
            } else {
              console.error("Error updating polygon:", response.statusText);
            }
          } catch (error) {
            console.error("Error updating polygon:", error);
          }
        }

        setIsLoading(false); // Hide loader when the request is completed
      });
      map.on("draw:deleted", async (e) => {
        const layers = e.layers;
        setIsLoading(true); // Show loader while making the request

        for (const layer of layers.getLayers()) {
          try {
            await deletePlanPolygonData(layer);
            dispatch(setDataChanged(true));
          } catch (error) {
            console.error("Error deleting polygon:", error);
          }
        }

        setIsLoading(false); // Hide loader when the request is completed
        setSuccessSnackbar(true);
        setSnackbarMessage("Delete successful!");
      });
      addPolygonsToMap(planData[selectedTab]);

      if (project) {
        if (project) {
          fetchTileLayer("ue_lc_kotoku2_tileset3")
            .then((data) => {
              if (data) {
                L.vectorGrid
                  .protobuf(data.data.url, {
                    rendererFactory: L.canvas.tile,
                    vectorTileLayerStyles: {
                      default: function (properties, zoom) {
                       
                        let fillColor = getFillColor(properties.type);
        
                        return {
                          weight: 0,
                          fillOpacity: 1,
                          fill: true,
                          fillColor: fillColor,
                        
                        };
                      },
                    },
                  })
                  .addTo(map);
              } else {
                console.error("Invalid data structure received.");
              }
            })
            .catch((error) => {
              // console.log("error", error);
            });
        }
        
      }

      setIsLoading(false);
      setRender(false);

      return () => {
        observer.disconnect();
        map.remove();
      };
    } catch (error) {
      // console.log("error", error);
    }
  }, [render, selectedBasemap]);

  useEffect(() => {
    if (tiffData && tiffData.length > 0) {
      console.log("tiff meta", tiffData);
      const imageUrl = tiffData[0].tiffurl;
      const boundingBox = tiffData[0].bbox.split(",").map(Number); // Split and convert to numbers
      const imageBounds = [
        [boundingBox[1], boundingBox[0]], // Southwest coordinates
        [boundingBox[3], boundingBox[2]], // Northeast coordinates
      ];

      if (map) {
        // Check if overlay exists, if yes, remove it
        if (overlay) {
          map.removeLayer(overlay);
        }

        const imageOverlayInstance = L.imageOverlay(
          imageUrl,
          imageBounds
        ).addTo(map);
        setOverlay(imageOverlayInstance);
        setMap(map);
      }
    } else {
      // No tiffData or empty array, remove overlay if it exists
      if (overlay) {
        map.removeLayer(overlay);
        setOverlay(null); // Clear the overlay state
      }
    }
  }, [tiffData, map]);

  useEffect(() => {
    setShowAlert(true);
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedTab]);

  const handlePolygonColorChange = async (selectedOption) => {
    setIsDialogOpen(false);

    if (selectedPolygon) {
      switch (selectedOption) {
        case "treecover":
          selectedPolygon.setStyle({
            fillColor: "#00FF00",
            color: "#00FF00",
            weight: 2,
          }); // Green
          break;
        case "openwater":
          selectedPolygon.setStyle({
            fillColor: "#0000FF",
            color: "#0000FF",
            weight: 2,
          }); // Blue
          break;
        case "grassland":
          selectedPolygon.setStyle({
            fillColor: "#FF0000",
            color: "#FF0000",
            weight: 2,
          }); // Pink
          break;
        default:
          break;
      }

      setIsLoading(true); // Show loader while making the request

      const polygonData = {
        project_id: project ? project.project_id : null,
        plan_id: selectedTab,
        geom: selectedPolygon.toGeoJSON().geometry,
        type: selectedOption,
        landcover_type: selectedOption,
        crown_width: null,
      };

      try {
        await savePlanPolygonData(polygonData);
        setSuccessSnackbar(true);
        dispatch(setDataChanged(true));
        setSnackbarMessage("Polygon save successful!");
      } catch (error) {
        console.error("Error saving polygon data:", error);
      }

      setIsLoading(false); // Hide loader when the request is completed
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "calc(100vh - 5vh)",
        height: "100%",
      }}
    >
      {/* Snackbar for success message */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={() => setSuccessSnackbar(false)}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{ backgroundColor: "#007BFF" }}
        />
      </Snackbar>

      <div style={{ display: "flex", flexGrow: 1, position: "relative" }}>
        {/* Loader in the center of the screen */}
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <CircularProgress/>
            <div style={{ marginTop: 16, color: "white" }}>In progress...</div>
          </div>
        )}

        <div
          ref={mapContainerRef}
          style={{ flex: "1", position: "relative", overflow: "hidden" }}
        ></div>
      </div>
      <PolygonDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onFormSubmit={handlePolygonColorChange}
      />
    </div>
  );
};

export default PlanMapComponent;
