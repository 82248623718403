// DonutChart.js
import React from 'react';

const DonutChart = ({ progress, fail, total }) => {
  const diameter = 40;
  const strokeWidth = 5;
  const radius = (diameter - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const progressOffset = ((progress / total) * circumference).toFixed(2);
  const failOffset = ((fail / total) * circumference).toFixed(2);

  return (
    <svg width={diameter} height={diameter}>
      <circle
        r={radius}
        cx={diameter / 2}
        cy={diameter / 2}
        fill="transparent"
        stroke="#e6e6e6"
        strokeWidth={strokeWidth}
      />
      <circle
        r={radius}
        cx={diameter / 2}
        cy={diameter / 2}
        fill="transparent"
        stroke="green" // Progress color
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={circumference - progressOffset}
        strokeLinecap="round"
        transform={`rotate(-90 ${diameter / 2} ${diameter / 2})`}
      />
      {fail > 0 && ( // Only show fail segment if there are failures
        <circle
          r={radius}
          cx={diameter / 2}
          cy={diameter / 2}
          fill="transparent"
          stroke="red" // Fail color
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - failOffset}
          strokeLinecap="round"
          transform={`rotate(-90 ${diameter / 2} ${diameter / 2})`}
        />
      )}
    </svg>
  );
};

export default DonutChart;
