import  {fetchProjects} from '../utils/carto'; 

// Action types
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';

// actions.js

// Action types
export const SET_AOI_POLYDATA = 'SET_AOI_POLYDATA';
export const SET_PLAN1_POLYDATA = 'SET_PLAN1_POLYDATA';
export const SET_PLAN2_POLYDATA = 'SET_PLAN2_POLYDATA';
export const SET_PLAN3_POLYDATA = 'SET_PLAN3_POLYDATA';

// actions.js

export const setAOISumInStore = (data) => ({
  type: 'SET_AOI_SUM',
  payload: data,
});

export const setGeotiffDataInStore = (data) => ({
  type: 'SET_GEOTIFF_DATA',
  payload: data,
});

export const setPlan1SumInStore = (data) => ({
  type: 'SET_PLAN1_SUM',
  payload: data,
});

export const setPlan2SumInStore = (data) => ({
  type: 'SET_PLAN2_SUM',
  payload: data,
});

export const setPlan3SumInStore = (data) => ({
  type: 'SET_PLAN3_SUM',
  payload: data,
});


// Action creators for polydata
export const setAOIPolyDataInStore  = (data) => ({
  type: SET_AOI_POLYDATA,
  payload: data,
});

export const setPlan1PolyDataInStore = (data) => ({
  type: SET_PLAN1_POLYDATA,
  payload: data,
});

export const setPlan2PolyDataInStore  = (data) => ({
  type: SET_PLAN2_POLYDATA,
  payload: data,
});

export const setPlan3PolyDataInStore  = (data) => ({
  type: SET_PLAN3_POLYDATA,
  payload: data,
});


// Action creators
export const fetchProjectsSuccess = (projects) => ({
  type: FETCH_PROJECTS_SUCCESS,
  payload: projects,
});

export const fetchProjectsData = () => {
  return async (dispatch) => {
    try {
      const projects = await fetchProjects();
      dispatch(fetchProjectsSuccess(projects));
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
};

export const receiveProjectData = (data) => ({
  type: 'RECEIVE_PROJECT_DATA',
  payload: data,
});


// Action types
export const SET_SELECTED_BASEMAP = 'SET_SELECTED_BASEMAP';

// Action creators
export const setSelectedBasemap = (basemap) => ({
  type: SET_SELECTED_BASEMAP,
  payload: basemap,
});

// Action types
export const SET_DATA_CHANGED = 'SET_DATA_CHANGED';

// Action creator
export const setDataChanged = (dataChanged) => ({
  type: SET_DATA_CHANGED,
  payload: dataChanged,
});
