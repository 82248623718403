/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import AWS from "aws-sdk"; // Import AWS SDK

import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Box,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
  Divider,
  Tab,
  Tabs,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import FileSelectDialog from "../Project/FileSelectDialog";
import { deleteCognitoUser, getCognitoUsers, updateCognitoUser, fetchSourceList, deleteSource } from "../../utils/carto";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import io from "socket.io-client";
import DonutChart from "./DonutChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

// const cognito = new AWS.CognitoIdentityServiceProvider();

const Dashboard = ({ langData }) => {
  const [sources, setSources] = useState([]); // Change this line
  const [users, setUsers] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState("");

  const [sourceSearchTerm, setSourceSearchTerm] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [socketStatus, setSocketStatus] = useState(null);
  const [total, setTotal] = useState();
  const [completed, setCompleted] = useState();

  // New state variables for confirmation dialog
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({
    itemName: "",
    type: "",
    cartodb_id: "",
    username: "",
  });
  const [refreshSources, setRefreshSources] = useState(false);
  const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);

  const [isEnabledState, setIsEnabledState] = useState({});

  const handleAdminToggle = (username) => {
    setIsEnabledState(prevState => {
        const newState = {
            ...prevState,
            [username]: !prevState[username],
        };
        updateCognitoUserAttribute(username, newState[username]);
        return newState;
    });
};


  useEffect(() => {
    fetchCognitoUsers();
  }, []);

  useEffect(() => {
    if (refreshSources) {
      fetchCognitoUsers();
      fetchSourcesList();
      setRefreshSources(false);
    }
  }, [refreshSources, openDialog]);
  useEffect(() => {
    socket.on("sourceStatus", (data) => {
      setSocketStatus(data.status);
      setTotal(data.totalSteps);
      setCompleted(data.completedSteps);
      // console.log("data", data);
      if (data.status === "success") {
        setRefreshSources(true);
      }
      // Update the socket message for the corresponding table
      setSources((prevSources) => {
        const updatedSources = [...prevSources];
        const index = updatedSources.findIndex(
          (source) => source.sourcename === data.tableName
        );
        if (index !== -1) {
          updatedSources[index].socketMessage = data.status;
          updatedSources[index].total = data.totalSteps;
          updatedSources[index].completed = data.completedSteps;
        }
        return updatedSources;
      });
    });
    return () => {
      socket.disconnect();
    };
  }, [socket]);
  useEffect(() => {
    fetchSourcesList();
  }, []);

  const handleDeleteUser = (user) => {
    setItemToDelete({
      itemName: user.Attributes.find(
        (attr) => attr.Name === "preferred_username"
      )?.Value.toLowerCase(),
      type: "users",
      cartodb_id: "",
      username: user.Username, // Assuming this is not needed for user deletion
    });
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      if (itemToDelete.type === "users") {
        await cognitoDeleteUser(itemToDelete.username);
        fetchCognitoUsers();
      }
      if (itemToDelete.type === "sources") {
        await deleteSource(itemToDelete.cartodb_id, itemToDelete.itemName);
        setSources((prevSources) =>
          prevSources.filter(
            (source) => source.sourcename !== itemToDelete.itemName
          )
        );
        fetchSourceList();
      }

      setConfirmationOpen(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
    }
  };

  const cognitoDeleteUser = async (username) => {
    try {
      console.log('deleteing user');
      setLoading(true);
      await deleteCognitoUser(username);
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.Username !== username)
      );
      
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSourcesList = async () => {
    try {
      const response = await fetchSourceList();

      setSources(response.data);
    } catch (error) {
      console.error("Error fetching sources list:", error);
    }
  };

  const fetchCognitoUsers = async () => {
    try {
        const data = await getCognitoUsers();
        setUsers(data.Users);
        // Set initial isEnabled state for all users
        const initialIsEnabledState = {};
        data.Users.forEach(user => {
            const isEnabledAttr = user.Attributes.find(attr => attr.Name === 'custom:isEnabled');
            initialIsEnabledState[user.Username] = isEnabledAttr ? isEnabledAttr.Value === 'true' : false;
        });
        setIsEnabledState(initialIsEnabledState);
    } catch (error) {
        console.error("Error fetching Cognito users:", error);
    }
};

  const updateCognitoUserAttribute = async (username, isAdminValue) => {
    try {      
      await updateCognitoUser(username, isAdminValue.toString());

      fetchCognitoUsers();
    } catch (error) {
      console.error("Error updating isAdmin attribute:", error);
    }
  };

  const handleAddTableToSources = (tableName) => {
    const prefixedTableName = `ue_lc_${tableName}`;
    setSources((prevSources) => [
      ...prevSources,
      { sourcename: prefixedTableName, socketMessage: null },
    ]);
  };

  const renderUserGridItems = (items, type) => {
    const filteredItems = items.filter((user) => {
      const usernameMatch = user.Attributes.find(
        (attr) => attr.Name === 'preferred_username'
      )?.Value.toLowerCase().includes(userSearchTerm.toLowerCase());
      const emailMatch = user.Attributes.find(
        (attr) => attr.Name === 'email'
      )?.Value.toLowerCase().includes(userSearchTerm.toLowerCase());
      const isAdmin = user.Attributes.find(
        (attr) => attr.Name === 'custom:isAdmin'
      )?.Value === 'true';
  
      return (usernameMatch || emailMatch) && !isAdmin;
    });

    filteredItems.sort((a, b) => {
      const dateA = new Date(a.UserCreateDate);
      const dateB = new Date(b.UserCreateDate);
      return dateB - dateA; // Sort in descending order, change to dateA - dateB for ascending
    });
  
    return (
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <TableContainer component={Paper} style={{ marginBottom: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Username
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  IsEnabled
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Created Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((user, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                }}
              >
                <TableCell>
                  <Typography>
                    {user.Attributes.find(
                      (attr) => attr.Name === 'preferred_username'
                    )?.Value}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {user.Attributes.find((attr) => attr.Name === 'email')?.Value}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Switch
                    checked={
                      user.Attributes.find(
                        (attr) => attr.Name === 'custom:isEnabled'
                      )?.Value === 'true'
                    }
                    onChange={() => handleAdminToggle(user.Username)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Typography>
                    {new Date(user.UserCreateDate).toISOString().slice(0, 10)
}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    aria-label="delete-user"
                    onClick={() => handleDeleteUser(user)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    );
  }
  
  
  
  
  
  
  
  
  const renderGridItems = (items, type) => {
    const filteredItems = items.filter((item) =>
      item.sourcename
        ?.toLowerCase()
        .includes(
          type === "sources"
            ? sourceSearchTerm.toLowerCase()
            : userSearchTerm.toLowerCase()
        )
    );

    return (
      <>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: 8 }}
        >
          <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {langData.sourceName}
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "bold",
                marginBottom: "5px",
                textAlign: "right",
                marginRight: "30px",
              }}
            >
              {langData.date}
            </Typography>
          </Grid>
          <Grid item xs={1} style={{ textAlign: "right" }}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginRight: "20px" }}
            >
              {langData.actions}
            </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "500px",
            scrollbarWidth: "thin",
            flex: 1,
          }}
        >
          <Grid container spacing={2}>
            {filteredItems.map((item, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={8}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body1">{item.sourcename}</Typography>
                  {item.total && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      {item.completed === item.total ? (
                        <CheckCircleIcon
                          style={{ color: "green", marginRight: "5px" }}
                        />
                      ) : (
                        <DonutChart
                          progress={item.completed}
                          total={item.total}
                        />
                      )}
                      <Typography>{`${item.completed}/${item.total}`}</Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ textAlign: "right", marginRight: "-20px" }}
                >
                  {item.created_dt && (
                    <Typography variant="body1" style={{ textAlign: "right" }}>
                      {new Date(item.created_dt).toLocaleDateString("en-GB")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={1} style={{ textAlign: "right" }}>
                  <IconButton
                    color="secondary"
                    aria-label="delete"
                    onClick={() =>
                      handleDelete(item.sourcename, type, item.cartodb_id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                {index < filteredItems.length - 1 && (
                  <Grid item xs={12}>
                    <Divider style={{ margin: "0 16px" }} />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </>
    );
  };

  const handleDelete = (itemName, type, cartodb_id) => {
    setItemToDelete({ itemName, type, cartodb_id });
    setConfirmationOpen(true);
  };

  const handleAddSourceClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // const handleAdd = (type) => {
  //   const newItem = prompt(
  //     `Enter the new ${type === "sources" ? "source" : "user"}`
  //   );
  //   if (newItem) {
  //     if (type === "sources") {
  //       setSources((prevSources) => [...prevSources, { sourcename: newItem }]);
  //     } else {
  //       setUsers((prevUsers) => [...prevUsers, newItem]);
  //     }
  //   }
  // };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };
  
  return (
    <div
      style={{
        padding: 16,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Paper elevation={2} style={{ marginBottom: 16 }}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {/* <Tab label="Sources" /> */}
          <Tab  label="Users" />
        </Tabs>
      </Paper>
      <TabPanel value={currentTab} index={1}>
        <div
          style={{
            padding: 16,
            minHeight: 300,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            marginBottom={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" gutterBottom>
              {langData.source}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputBase
                placeholder="Search..."
                value={sourceSearchTerm}
                onChange={(e) => setSourceSearchTerm(e.target.value)}
                endAdornment={
                  sourceSearchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSourceSearchTerm("")}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              <IconButton color="primary" aria-label="search">
                <SearchIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="refresh"
                onClick={() => setRefreshSources(true)}
              >
                <RefreshIcon />
              </IconButton>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleAddSourceClick}
              >
                  {langData.addSource}
              </Button> */}
            </div>
          </Box>
          {sources.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No sources available.
            </Typography>
          ) : (
            <div style={{ flex: 1 }}>{renderGridItems(sources, "sources")}</div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={currentTab} index={0}>
        <div
          style={{
            padding: 16,
            minHeight: 300,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            marginBottom={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" gutterBottom>
              {langData.users}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputBase
                placeholder="Search..."
                value={userSearchTerm}
                onChange={(e) => setUserSearchTerm(e.target.value)}
              />
              <IconButton color="primary" aria-label="search">
                <SearchIcon />
              </IconButton>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => handleAdd("users")}
              >
                Add User
              </Button> */}
            </div>
          </Box>
          {users.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No users available.
            </Typography>
          ) : (
            <div style={{ flex: 1 }}>{renderUserGridItems(users, "users")}</div>
          )}
        </div>
      </TabPanel>
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {itemToDelete.itemName}?
          </Typography>
          {loading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)} color="primary">
            {langData.cancel}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            {langData.confirmDelete}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ flex: 1 }}>
        <FileSelectDialog
          open={openDialog}
          onClose={handleDialogClose}
          onTableAdd={handleAddTableToSources}
        />
      </div>
    </div>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
    {value === index && (
      <Box
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
        {children}
      </Box>
    )}
  </div>
);

export default Dashboard;
