/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"; // Import Material-UI components
import mapboxgl from "mapbox-gl";

import "leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled";

import { fetchMapData, updateProject, fetchTileLayer } from "../../utils/carto";
import { useSelector } from "react-redux";
import { getFillColor } from "../../utils/functions";

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN;

const AoiMapComponent = ({
  showProjectList,
  project,
  onProjectDataChange,
  langData,
  isFormCollapsed,
}) => {
  const mapContainerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProjectUpdate, setIsLoadingProjectUpdate] = useState(false);
  const [isValidProject, setIsValidProject] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const tiff_url =
    "https://georaster-layer-for-leaflet.s3.amazonaws.com/wind_speed_usa.tif";

  const [popupMessage, setPopupMessage] = useState("");
  const selectedBasemap = useSelector((state) => state.selectedBasemap);

  const center = [35.68334155120243, 139.7670395118941];

  useEffect(() => {
    try {
      const mapContainer = mapContainerRef.current;

      if (!mapContainer) {
        throw new Error("Map container not found");
      }

      const map = L.map(mapContainer).setView(center, 7);

      if (!map) {
        throw new Error("Failed to initialize the map");
      }

      L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/${selectedBasemap}/tiles/{z}/{x}/{y}?access_token=${mapboxgl.accessToken}`,
        {
          maxZoom: 19,
          attribution:
            '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> contributors',
        }
      ).addTo(map);

      const handleResize = () => {
        
        if (map) {
          map.invalidateSize();
        }
      };

      const observer = new ResizeObserver(handleResize);
      observer.observe(mapContainer);

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControlEnabled = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
          edit: true,
          remove: true,
        },
        draw: {
          polygon: {
            shapeOptions: {
              color: "#FF00FF", // Pink outline color
              fillColor: "#FF00FF", // Pink fill color
              weight: 2, // Outline width
            },
          },
          polyline: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          marker: false,
        },
      });

      const drawControlDisabled = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
          edit: true,
          remove: true,
        },
        draw: {
          polygon: false,
          polyline: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          marker: false,
        },
      });

      map.addControl(drawControlEnabled);

      map.on("draw:created", async (e) => {
        if (!isValidProject) {
          setPopupMessage(
            "Save project by selecting source before drawing an AOI polygon"
          );
          setShowPopup(true);
          return;
        }

        const layer = e.layer;
        const polygonData = {
          geom: layer.toGeoJSON().geometry,
        };

        setIsLoadingProjectUpdate(true);

        try {
          const response = await updateProject(project.cartodb_id, polygonData);

          if (response.status === 200) {
            if (onProjectDataChange) {
              onProjectDataChange(response.data.data);
            }
          }
        } catch (error) {
          console.error("Error creating polygon:", error);
        } finally {
          setIsLoadingProjectUpdate(false); // Hide the loader when the request is complete
        }
      });

      map.on("draw:edited", async (e) => {
        const layers = e.layers;
        layers.eachLayer(async (layer) => {
          const polygonData = {
            geom: layer.toGeoJSON().geometry,
          };
          setIsLoadingProjectUpdate(true);
          try {
            const response = await updateProject(
              project.cartodb_id,
              polygonData
            );

            // const res = await getProject(project.cartodb_id);
            if (onProjectDataChange && response.status === 200) {
              onProjectDataChange(response.data.data);
            }
          } catch (error) {
            console.error("Error editing polygon:", error);
          } finally {
            setIsLoadingProjectUpdate(false); // Hide the loader when the request is complete
          }
        });
      });

      map.on("draw:deleted", async (e) => {
        const layers = e.layers;
        layers.eachLayer(async (layer) => {
          const polygonData = {
            geom: null,
          };
          setIsLoadingProjectUpdate(true);
          try {
            const response = await updateProject(
              project.cartodb_id,
              polygonData
            );

            map.removeControl(drawControlDisabled);
            map.addControl(drawControlEnabled);
            // const res = await getProject(project.cartodb_id);
            if (onProjectDataChange && response.status === 200) {
              onProjectDataChange(response.data.data);
            }
          } catch (error) {
            console.error("Error Deleting polygon:", error);
          } finally {
            setIsLoadingProjectUpdate(false);
          }
        });
      });

      if (project) {
        if (project) {
          fetchTileLayer("ue_lc_kotoku2_tileset3")
            .then((data) => {
              if (data) {
                L.vectorGrid
                  .protobuf(data.data.url, {
                    rendererFactory: L.canvas.tile,
                    vectorTileLayerStyles: {
                      default: function (properties, zoom) {
                        let fillColor = getFillColor(properties.type);
                        return {
                          weight: 0,
                          fillOpacity: 1,
                          fill: true,
                          fillColor: fillColor,
                        };
                      },
                    },
                  })
                  .addTo(map);
              } else {
                console.error("Invalid data structure received.");
              }
            })
            .catch((error) => {});
        }

        if (project.geom) {
          // Check if project.geom is a string before processing it
          if (typeof project.geom === "string") {
            const coordinates = project.geom
              .replace("POLYGON((", "")
              .replace("))", "")
              .split(",")
              .map((pair) => {
                const [lng, lat] = pair.trim().split(" ").map(parseFloat);
                return [lat, lng];
              });

            if (coordinates.length > 0) {
              const polygonStyle = {
                fillColor: "#FF00FF", // Pink fill color
                color: "#FF00FF", // Pink outline color
                weight: 2, // Outline width
              };
              const polygon = L.polygon(coordinates, polygonStyle);
              drawnItems.addLayer(polygon);
              map.removeControl(drawControlEnabled);
              map.addControl(drawControlDisabled);
              map.fitBounds(polygon.getBounds(), { padding: [20, 20] });
              const currentZoom = map.getZoom();
              const newZoom = currentZoom - 1;
              map.setZoom(newZoom);
            }
          } else if (project.geom) {
            const coordinates = project.geom.coordinates[0].map(
              ([lng, lat]) => [lat, lng]
            );

            if (coordinates.length > 0) {
              const polygonStyle = {
                fillColor: "#FF00FF", // Pink fill color
                color: "#FF00FF", // Pink outline color
                weight: 2, // Outline width
              };
              const polygon = L.polygon(coordinates, polygonStyle);
              drawnItems.addLayer(polygon);
              map.removeControl(drawControlEnabled);
              map.addControl(drawControlDisabled);
              map.fitBounds(polygon.getBounds(), { padding: [20, 20] });
              const currentZoom = map.getZoom();
              const newZoom = currentZoom - 1;
              map.setZoom(newZoom);
            } else {
              console.error(
                "No valid coordinates found in project.geom:",
                project.geom
              );
            }
          } else {
            console.error("Invalid project.geom:", project.geom);
          }
        }
      }

      setIsLoading(false);
      return () => {
        observer.disconnect();
        map.remove();
      };
    } catch (error) {
      console.error("Error in AoiMapComponent:", error);
      // Handle error gracefully
      setIsLoading(false);
      setIsLoadingProjectUpdate(false);
      setShowPopup(true);
      setPopupMessage("An error occurred while loading the map component.");
    }
  }, [project, isValidProject, selectedBasemap, isFormCollapsed]);

  useEffect(() => {
    setIsValidProject(
      project && project.cartodb_id !== undefined && project.cartodb_id !== null
    );
  }, [project]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "calc(100vh - 5vh)",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", flexGrow: 1, position: "relative" }}>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </div>
        )}
        {isLoadingProjectUpdate && (
          <Dialog
            open={isLoadingProjectUpdate}
            onClose={() => setIsLoadingProjectUpdate(false)}
          >
            <DialogTitle>Loading...</DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            </DialogContent>
          </Dialog>
        )}
        <div
          ref={mapContainerRef}
          style={{ flex: "1", position: "relative", overflow: "hidden" }}
        >
          {/* <CartoDeckLayer/> */}
        </div>
      </div>
      <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <p>{popupMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPopup(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AoiMapComponent;
