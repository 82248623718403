import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PlanDescription = ({ title,name, description, isExpanded, onExpand }) => {
  const handleChange = () => {
    onExpand(isExpanded ? null : title);
  };

  const formatDescription = (description) => {
    const words = description.split(" ");
    const maxLengthPerLine = 35; // Adjust this value as needed

    let formattedDescription = "";
    let lineLength = 0;

    for (const word of words) {
      if (lineLength + word.length > maxLengthPerLine) {
        formattedDescription += "\n" + word + " ";
        lineLength = word.length + 1;
      } else {
        formattedDescription += word + " ";
        lineLength += word.length + 1;
      }
    }

    return formattedDescription.trim();
  };



  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      style={{
        marginBottom: "8px",
        borderRadius: "8px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        style={{
          backgroundColor: "white",
          borderBottom: "1px solid #e0e0e0",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <Typography variant="body1" style={{ fontSize: "14px", fontWeight: "bold" }}>
          {name}
        </Typography>
      </AccordionSummary>
      <div style={{ margin: "1px 0", textAlign: "center" }}>
        
          <div
            style={{
              maxHeight: "72px", // Adjust as needed
              overflowY: "auto",
              whiteSpace: "pre-wrap",
              textAlign: "left",
              fontSize: "12px",
            }}
          >
            <p style={{ margin: "0" }} dangerouslySetInnerHTML={{ __html: formatDescription(description) }} />
          </div>
        
      </div>
    </Accordion>
  );
};

export default PlanDescription;
