/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import { CircularProgress, Button } from "@mui/material";
import { useMapContext } from "../../Context/MapContext";
import {
  fetchModels,
  updateProject,
  getPlanWiseGeoTiffData,
  deletePlanWiseGeoTiffData,
  getProject,
} from "../../utils/carto";
import UploadGeoTIFFDialog from "./GeotiffDialogBox";
import { setDataChanged } from "../../redux/actions";
import { useDispatch } from "react-redux";

// CKEditor 5 imports
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const GeoTIFFInfo = ({ tiffImgName, onDeleteClick }) => (
  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    {tiffImgName ? (
      <span style={{ margin: 0, fontWeight: "bold", color: "blue" }}>
        {tiffImgName}
      </span>
    ) : (
      <span>No Tiff files</span>
    )}
    {tiffImgName && (
      <DeleteIcon
        onClick={onDeleteClick}
        style={{ cursor: "pointer", color: "red" }}
      />
    )}
  </div>
);

const MapTabs = ({ setSelectedTab, project,langData }) => {
  const editorConfiguration = {
    toolbar: {
      items: ["bold", "italic", "underline", "|", "undo", "redo"],
    },
  };
  
  let tiffImgName = "";
  const { setDrawEnabled } = useMapContext();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [editorData, setEditorData] = useState({
    1: "",
    2: "",
    3: "",
  });

  const [geoTIFFDialogOpen, setGeoTIFFDialogOpen] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [tiffData, setTiffData] = useState([]);
  const [loadingTiffData, setLoadingTiffData] = useState(true);
  const [isAutoSaving, setIsAutoSaving] = useState(false);

  if (tiffData && tiffData.length > 0) {
    tiffImgName = tiffData[0].filename;
  }

  const [selectedModels, setSelectedModels] = useState({
    1: [],
    2: [],
    3: [],
  });

  useEffect(() => {
    async function fetchModelsData() {
      try {
        const response = await fetchModels();
        setModelData(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    }

    fetchModelsData();
  }, []);

  useEffect(() => {
    async function fetchTiff(projectId, activeTab) {
      try {
        setLoadingTiffData(true);
        const response = await getPlanWiseGeoTiffData(projectId, activeTab);
        setTiffData(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      } finally {
        setLoadingTiffData(false);
      }
    }

    fetchTiff(projectId, activeTab);
  }, [activeTab, deleteConfirmationOpen, geoTIFFDialogOpen]);

  useEffect(() => {
    async function fetchData() {
      try {
        const projectData = await getProject(project.cartodb_id);
        if (projectData) {
          setEditorData({
            1: projectData.data[0].plan1info || "",
            2: projectData.data[0].plan2info || "",
            3: projectData.data[0].plan3info || "",
          });
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    }

    fetchData();
  }, [project]);

  useEffect(() => {
    if (isAutoSaving) {
      updateModelsForProject();
    }
  }, [selectedModels]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setDrawEnabled(true);
    setSelectedTab(newValue);
  };

  const handleModelChange = (selectedOptions) => {
    setIsAutoSaving(true);
    setSelectedModels((prevSelectedModels) => ({
      ...prevSelectedModels,
      [activeTab]: selectedOptions,
    }));
  };

  const handleGeoTIFFUploadClick = () => {
    setGeoTIFFDialogOpen(true);
  };

  const handleGeoTIFFDeleteClick = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteConfirmationOpen(false);
    try {
      const response = await deletePlanWiseGeoTiffData(projectId, activeTab);
      if (response) {
        console.log("File deleted successfully");
        dispatch(setDataChanged(true));
      } else {
        console.error("File upload failed");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const updateModelsForProject = async () => {
    const cartodb_id = project.cartodb_id;
    const projectData = {
      model: JSON.stringify([
        { 1: selectedModels[1].map((model) => model.value) },
        { 2: selectedModels[2].map((model) => model.value) },
        { 3: selectedModels[3].map((model) => model.value) },
      ]),
    };

    try {
      const response = await updateProject(cartodb_id, projectData);
      if (response.status === 200) {
        setIsAutoSaving(false);
      }
    } catch (error) {}
  };

  const handleEditorBlur = async (event, editor) => {
    const data = editor.getData();
    setEditorData((prevEditorData) => ({
      ...prevEditorData,
      [activeTab]: data,
    }));
    setIsAutoSaving(true); // Enable auto-saving
    await updateProject(project.cartodb_id, { [`plan${activeTab}info`]: data });
    setIsAutoSaving(false); // Disable auto-saving
  };

  if (project.cartodb_id == null) {
    return <p>Please save the project to enable tabs</p>;
  }

  return (
    <>
      <div>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="AOI" />
          <Tab label="Plan 1" />
          <Tab label="Plan 2" />
          <Tab label="Plan 3" />
        </Tabs>
        {isAutoSaving && (
          <div>
            <CircularProgress color="primary" /> Auto-saving in progress...
          </div>
        )}
        {activeTab === 0 && (
          <div>
            <p>{langData.aoiInstructions}</p>
          </div>
        )}

        {activeTab >= 1 && activeTab <= 3 && (
          <>
            <div>
              {/* <p>Info for Plan {activeTab} tab.</p> */}
              <p>{langData[`plan${activeTab}Instructions`]}</p>

              <div style={{ marginTop: "10px", maxHeight: "200px", overflowY: "auto" }}>
                <CKEditor
                config={ editorConfiguration}
                  editor={ClassicEditor}
                  data={editorData[activeTab]}
                  onBlur={handleEditorBlur}
                />
              </div>

              {loadingTiffData ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <GeoTIFFInfo
                  tiffImgName={tiffData.length > 0 ? tiffData[0].filename : ""}
                  onDeleteClick={handleGeoTIFFDeleteClick}
                />
              )}

              <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the GeoTIFF image?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    color="primary"
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGeoTIFFUploadClick}
              >
                Upload GeoTIFF
              </Button>
            </div>
          </>
        )}
      </div>
      <UploadGeoTIFFDialog
        open={geoTIFFDialogOpen}
        onClose={() => setGeoTIFFDialogOpen(false)}
        planId={activeTab}
      />
    </>
  );
};

export default MapTabs;
