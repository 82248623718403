import mapboxgl from "mapbox-gl";
import Chart from "chart.js/auto"; // Import Chart.js

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN;

const calculateRGBColor = (hsi) => {
  let rgbColor;

  if (hsi > 0.95 && hsi <= 1.0) {
    rgbColor = "rgb(55, 97, 20)";
  } else if (hsi > 0.9 && hsi <= 0.95) {
    rgbColor = "rgb(83, 124, 29)";
  } else if (hsi > 0.85 && hsi <= 0.9) {
    rgbColor = "rgb(116, 152, 36)";
  } else if (hsi > 0.8 && hsi <= 0.85) {
    rgbColor = "rgb(157, 185, 44)";
  } else if (hsi > 0.75 && hsi <= 0.8) {
    rgbColor = "rgb(202, 218, 52)";
  } else if (hsi > 0.7 && hsi <= 0.75) {
    rgbColor = "rgb(255, 255, 59)";
  } else if (hsi > 0.65 && hsi <= 0.7) {
    rgbColor = "rgb(245, 216, 50)";
  } else if (hsi > 0.6 && hsi <= 0.65) {
    rgbColor = "rgb(235, 172, 41)";
  } else if (hsi > 0.55 && hsi <= 0.6) {
    rgbColor = "rgb(228, 131, 30)";
  } else if (hsi > 0.5 && hsi <= 0.55) {
    rgbColor = "rgb(223, 90, 16)";
  } else if (hsi >= 0 && hsi <= 0.5) {
    rgbColor = "rgb(219, 38, 4)";
  }

  return rgbColor;
};

function getFillColor(type) {
  switch (type) {
    case "grass":
      return "green";
    case "tree":
      return "darkgreen";
    case "water":
      return "blue";
    case "bush":
      return "red";
    default:
      return "pink";
  }
}

const getTimestampedFilename = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `maps_${year}${month}${day}_${hours}${minutes}${seconds}.pdf`;
};

const handleMapMove = (
  mapRef,
  newCenter,
  newZoom,
  movement,
  mapAoiRef,
  mapPlan1Ref,
  mapPlan2Ref,
  mapPlan3Ref
) => {
  const duration = 0;
  if (!movement && mapRef) {
    const targetRefs = [mapAoiRef, mapPlan1Ref, mapPlan2Ref, mapPlan3Ref];
    const currentIndex = targetRefs.findIndex((ref) => ref === mapRef);
    if (currentIndex !== -1) {
      movement = true;

      for (let i = 0; i < targetRefs.length; i++) {
        if (i !== currentIndex) {
          if (targetRefs[i].current) {
            targetRefs[i].current.easeTo({
              center: newCenter,
              zoom: newZoom,
              duration,
            });
          }
        }
      }

      movement = false;
    }
  }
};

const addPopupToMap = (map, layerId, popupContent, popupRef) => {
  map.on("mousemove", layerId, (e) => {
    map.getCanvas().style.cursor = "pointer";
    popupRef.current.setLngLat(e.lngLat).setHTML(popupContent).addTo(map);
  });

  map.on("mouseleave", layerId, () => {
    map.getCanvas().style.cursor = "";
    popupRef.current.remove();
  });
};

const createMap = (
  containerId,
  initialCenter,
  initialZoom,
  mapRef,
  selectedBasemap,
  movement,
  mapAoiRef,
  mapPlan1Ref,
  mapPlan2Ref,
  mapPlan3Ref
) => {
  if (containerId) {
    const map = new mapboxgl.Map({
      container: containerId,
      style: `mapbox://styles/mapbox/${selectedBasemap}`,
      center: initialCenter,
      zoom: initialZoom,
      pitch: 45,
      bearing: 0,
    });

    mapRef.current = map;

    const navControl = new mapboxgl.NavigationControl();
    mapRef.current.addControl(navControl, "bottom-right");

    map.on("move", () => {
      const newCenter = map.getCenter().toArray();
      const newZoom = map.getZoom();
      handleMapMove(
        mapRef,
        newCenter,
        newZoom,
        movement,
        mapAoiRef,
        mapPlan1Ref,
        mapPlan2Ref,
        mapPlan3Ref
      );
    });

    return map;
  }
};

const addBufferPolygonToMap = (map, polygonCoords) => {
  if (polygonCoords) {
    const bufferGeoJson = {
      type: "Feature",
      geometry: {
        type: "LineString", // Change to LineString for a line representation
        coordinates: polygonCoords[0], // Use the first set of coordinates from the polygon
      },
      properties: {
        description: "Buffer Polygon",
      },
    };
    map.addLayer({
      id: `buffer-layer-${polygonCoords[0]}-${polygonCoords[1]}`, // Unique layer ID for each buffer polygon
      type: "line", // Change type to "line" for a line representation
      source: {
        type: "geojson",
        data: bufferGeoJson,
      },
      paint: {
        "line-color": "#000", // Red color for the line
        "line-width": 3, // Adjust line width as needed
      },
    });
  }
};

const addBufferPolygonToBufferMap = async (map, polygonCoords) => {
  if (polygonCoords) {
    const bufferGeoJson = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: polygonCoords[0],
      },
      properties: {
        description: "Buffer Polygon",
      },
    };

    // Convert the style loading check into a promise-based approach
    await new Promise((resolve, reject) => {
      if (map.isStyleLoaded()) {
        resolve();  // Resolve immediately if the style is already loaded
      } else {
        map.once("style.load", resolve);  // Resolve on style load
      }
    });

    // After the style is confirmed to be loaded, add the layer
    addLayerToMap(map, bufferGeoJson, polygonCoords);
  }
};

const addLayerToMap = (map, bufferGeoJson, polygonCoords) => {
  map.addLayer({
    id: `buffer-layer-${polygonCoords[0][0]}-${polygonCoords[0][1]}`,
    type: "line",
    source: {
      type: "geojson",
      data: bufferGeoJson,
    },
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
  });
};


const addPolygonsToMap = (map, data, circleCoords, aoi) => {
  const addAllPolygons = () => {
    data.forEach((polygon) => {
      const { coordinates, stats } = polygon;

      if (stats) {
        if (stats.type === "Polygon") {
          addPolygonToMap(map, stats, coordinates[0]);
        } else if (stats.type === "MultiPolygon") {
          addMultipolygonToMap(map, stats, coordinates);
        }
      }
    });

    // Add circles
    addBufferPolygonToMap(map, circleCoords);
    // Add AOI polygon
    addAoiPolygonToMap(map, aoi);
  };

  if (map.isStyleLoaded()) {
    addAllPolygons();
  } else {
    map.on("load", addAllPolygons);
  }
};


const addAoiPolygonToMap = (map, polygonGeoJson) => {
  if (polygonGeoJson) {
   
    map.addLayer({
      id: `polygon-outline-layer-${polygonGeoJson.coordinates[0][0][0]}-${polygonGeoJson.coordinates[0][0][1]}`, // Unique layer ID for each polygon outline
      type: "line",
      source: {
        type: "geojson",
        data: polygonGeoJson,
      },
      paint: {
        "line-color": "#000", // Red color outline
        "line-width": 2, // Adjust line width as needed
      },
    });
  } else {
 
  }
};


const addPolygonToMap = (map, stats, coordinates) => {
  const transformedCoordinates = coordinates.map(([lng, lat]) => [lng, lat]);

  const rgbColor = calculateRGBColor(stats.hsi);

  map.addSource(`polygon-source-${stats.id}`, {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [transformedCoordinates],
      },
      properties: stats,
    },
  });

  map.addLayer({
    id: `polygon-layer-${stats.id}`,
    type: "fill",
    source: `polygon-source-${stats.id}`,
    paint: {
      "fill-color": rgbColor,
      "fill-opacity": 0.5,
      "fill-outline-color": "transparent",
      "fill-antialias": true,
      "fill-translate": [0, 0],
      "fill-translate-anchor": "viewport",
    },
  });

  const bounds = new mapboxgl.LngLatBounds();
  transformedCoordinates.forEach((lngLat) => {
    bounds.extend(lngLat);
  });

  const padding = 20;

  map.fitBounds(bounds, {
    padding,
    zoom: 12,
  });
};
const addMultipolygonToMap = (map, stats, coordinates) => {
  const transformedCoordinates = coordinates.map((polygon) =>
    polygon.map((ring) => ring.map(([lng, lat]) => [lng, lat]))
  );

  const rgbColor = calculateRGBColor(stats.hsi);

  map.addSource(`multipolygon-source-${stats.id}`, {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: transformedCoordinates,
      },
      properties: stats,
    },
  });

  map.addLayer({
    id: `multipolygon-layer-${stats.id}`,
    type: "fill",
    source: `multipolygon-source-${stats.id}`,
    paint: {
      "fill-color": rgbColor,
      "fill-opacity": 0.5,
      "fill-outline-color": "transparent",
      "fill-antialias": true,
      "fill-translate": [0, 0],
      "fill-translate-anchor": "viewport",
    },
  });

  const bounds = new mapboxgl.LngLatBounds();
  transformedCoordinates.forEach((polygon) => {
    polygon.forEach((ring) => {
      ring.forEach((lngLat) => {
        bounds.extend(lngLat);
      });
    });
  });

  const padding = 20;

  map.fitBounds(bounds, {
    padding,
    zoom: 12,
  });
};

function getCenterOfPolygon(coords) {
  let minLat = coords[0][1],
    maxLat = coords[0][1];
  let minLng = coords[0][0],
    maxLng = coords[0][0];

  coords.forEach((coord) => {
    if (coord[1] < minLat) minLat = coord[1];
    if (coord[1] > maxLat) maxLat = coord[1];
    if (coord[0] < minLng) minLng = coord[0];
    if (coord[0] > maxLng) maxLng = coord[0];
  });

  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  return [centerLng, centerLat];
}

const createBufferMap = (basemapStyle, coordinates, zoomLevel) => {
  const map = new mapboxgl.Map({
    container: document.createElement("div"), // This will create a new div for each map instance
    style: `mapbox://styles/mapbox/${basemapStyle}`,
    center: getCenterOfPolygon(coordinates),
    zoom: 12,
    maxZoom: 20,
  });
  const scaleControl = new mapboxgl.ScaleControl({
    maxWidth: 100,
    unit: "metric",
  });
  map.addControl(scaleControl, "top-right");
  return map;
};

const handleBufferMapIdle = (bufferMap, bufferMapsContainer, i,langData) => {
  

  const canvas = document.createElement("canvas");
  
  const aspectRatio =
    bufferMap.getCanvas().width / bufferMap.getCanvas().height;
  canvas.width = 800;
  canvas.height = canvas.width / aspectRatio;
  const ctx = canvas.getContext("2d");

  // Draw the map onto the canvas
  ctx.drawImage(bufferMap.getCanvas(), 0, 0, canvas.width, canvas.height);

  // Access and adjust scale control display
  const scaleControl = bufferMap
    .getContainer() 
    .querySelector(".mapboxgl-ctrl-scale");
  if (scaleControl && scaleControl.textContent) {
    const scaleText = scaleControl.textContent;
    const scaleWidth = 100;
    const scaleHeight = 30;
    const xOffset = canvas.width - scaleWidth - 20;
    const yOffset = canvas.height - scaleHeight - 20;

    ctx.fillStyle = "white";
    ctx.fillRect(xOffset, yOffset, scaleWidth, scaleHeight);
    ctx.strokeStyle = "black";
    ctx.strokeRect(xOffset, yOffset, scaleWidth, scaleHeight);

    ctx.fillStyle = "black";
    ctx.font = "16px Arial bold";
    ctx.fillText(scaleText, xOffset + 5, yOffset + 22);
  }

  // Creating the map image element
  const mapImage = document.createElement("img");
  mapImage.src = canvas.toDataURL("image/jpeg");
  mapImage.style.width = "75%";
  mapImage.style.height = "360px";
  mapImage.style.display = "block";
  mapImage.style.margin = "0 auto";

  if (i > 0) {
    mapImage.style.marginTop = "50px";
  }

  // Creating the legend
  const legend = document.createElement("div");
  legend.style.width = "20%";
  legend.style.backgroundColor = "white";
  legend.style.padding = "20px";
  legend.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
  legend.style.marginTop = "20px"; // Add this line to move the legend down
  legend.innerHTML = `
      <ul style="list-style: none; padding: 0; margin: 0; font-size: 14px; color: black;">
        <li><span style="height: 15px; width: 15px; background-color: darkgreen; display: inline-block;"></span> ${langData.tree}</li>
        <li><span style="height: 15px; width: 15px; background-color: green; display: inline-block;"></span> ${langData.grass}</li>
        <li><span style="height: 15px; width: 15px; background-color: red; display: inline-block;"></span> ${langData.bush}</li>
        <li><span style="height: 15px; width: 15px; background-color: blue; display: inline-block;"></span> ${langData.water}</li>
      </ul>
  `;

  // Append elements to container
  const container = document.createElement("div");
  container.style.display = "flex";
  container.style.justifyContent = "space-between";
  container.style.alignItems = "start";
  container.style.width = "100%";
  container.appendChild(legend);
  container.appendChild(mapImage);
  bufferMapsContainer.appendChild(container);

  // Remove the map after capturing the image
  bufferMap.remove();
};

const processBufferArray = async (
  bufferArray,
  bufferMapsContainer,
  selectedBasemap,
  aoiPolygon,
  langData
) => {
  for (let i = 0; i < bufferArray.length; i++) {
    await new Promise(async (resolve, reject) => {
      try {
        // Create the buffer map with the selected base map and AOI polygon coordinates
        const bufferMap = createBufferMap(
          selectedBasemap,
          aoiPolygon.coordinates[0],
          12
        );
        // Add polygon outline to the map
        await addPolygonOutlineToMap(bufferMap, aoiPolygon);
        // Add buffer polygon data to the map
        await addBufferPolygonToBufferMap(
          bufferMap,
          bufferArray[i].polygonsData
        );
        // Add a tile layer to the buffer map
        await addTileLayerToBufferMap(bufferMap);
        // Setup to handle map's idle event
        bufferMap.on("idle", () => {
          handleBufferMapIdle(bufferMap, bufferMapsContainer, i,langData);
          resolve(); // Resolve the promise when the map is idle
        });
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  }
};

const addTileLayerToBufferMap = (bufferMap) => {
  return new Promise((resolve, reject) => {
    bufferMap.on("load", () => {
     

      try {
        bufferMap.addSource("tiledata", {
          type: "vector",
          tiles: [
            "https://gcp-asia-northeast1.api.carto.com/v3/maps/carto_dw/tileset/{z}/{x}/{y}?name=carto-dw-ac-moe5kln.shared.ue_lc_kotoku2_tileset3&partition=0_20_931416_931626_412810_413121_3999_1&formatTiles=mvt&cache=1707283207517&v=3.0&access_token=eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbW9lNWtsbiIsImp0aSI6IjliZGQyNGFiIn0.U7LQBvOOVMeCOcm2Ubu3at0BVrg3yCR3yMt0Govt0uc",
          ],
        });

        bufferMap.addLayer({
          id: "tiledata",
          type: "fill", // Changed from 'line' to 'fill' for area features
          source: "tiledata",
          "source-layer": "default",
          paint: {
            "fill-color": [
              "match",
              ["get", "type"], // Get the 'type' property from the feature
              "tree",
              "darkgreen", // Green color for trees
              "grass",
              "green", // Light green for grass
              "bush",
              "red", // Yellow for bushes
              "water",
              "blue", // Blue for water
              "pink", // Default color if none of the above types match
            ],
            "fill-opacity": 0.3,
          },
        });

        

        bufferMap.on("styledata", () => {
          
          // Further debugging to check if layers are rendered
          const layers = bufferMap.getStyle().layers;
          if (layers.find((layer) => layer.id === "tiledata")) {
            
            resolve();
          } else {
            console.error("Tile layer not found in style configuration");
            reject("Tile layer configuration failed");
          }
        });
      } catch (error) {
        console.error("Error adding tile layer:", error);
        reject(error);
      }
    });

    bufferMap.on("error", function (e) {
      console.error("Critical map error:", e.error);
      reject(e.error);
    });
  });
};

const addPolygonOutlineToMap = (map, polygonGeoJson) => {
  map.on("load", () => {
    map.addLayer({
      id: `polygon-outline-layer-${polygonGeoJson.coordinates[0][0][0]}-${polygonGeoJson.coordinates[0][0][1]}`, // Unique layer ID for each polygon outline
      type: "line",
      source: {
        type: "geojson",
        data: polygonGeoJson,
      },
      paint: {
        "line-color": "#000", // Red color outline
        "line-width": 2, // Adjust line width as needed
        
      },
    });
  });
};



const addPageToPdf = async (map, plan, pdfContent, template) => {
  const page = document.createElement("div");
  pdfContent.appendChild(page);

  await new Promise((resolve) => {
    map.on("load", () => {
      addPolygonsToMap(map, plan.polygonsData);
    });

    map.once("idle", () => {
      const canvas = document.createElement("canvas");
      canvas.width = map.getCanvas().width;
      canvas.height = map.getCanvas().height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(map.getCanvas(), 0, 0, canvas.width, canvas.height);

      // Replace placeholders in the template
      const pageHtml = template
        .replace("{title}", plan.name)
        .replace(
          "{content}",
          `<img src="${canvas.toDataURL(
            "image/jpeg"
          )}" style="width: 80%; height: auto; margin: 0 auto; display: block;" />`
        );

      // Add header and descriptions to the first page only
      if (!pdfContent.querySelector(".header")) {
        const headerHtml = `
          <div class="header">
            <h1 style="text-align: center;">Project Name: Name</h1>
            <hr />
            <p>Description: Your description here</p>
            <p>Model Used: Your model name here</p>
            <p>Plan 1 Description: Your plan 1 description here</p>
            <p>Plan 2 Description: Your plan 2 description here</p>
            <p>Plan 3 Description: Your plan 3 description here</p>
          </div>
          <div style="position: absolute; top: 20px; right: 20px;">${getCurrentDate()}</div>
          <hr />
        `;
        page.innerHTML = headerHtml + pageHtml;
      } else {
        page.innerHTML = pageHtml;
      }

      // Cleanup: Remove the map instance after processing
      map.remove();

      resolve();
    });
  });
};

// Helper function to get current date in the desired format
const getCurrentDate = () => {
  const now = new Date();
  return `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
};

const getStatsTableHtml = (polygonsData) => {
  const showSideBySide = polygonsData.length > 18;

  return (
    `<div style="width: ${showSideBySide ? "50%" : "100%"}; margin: 0 auto;">` +
    `<div style="display: flex; flex-wrap: wrap;">` +
    polygonsData
      .map(
        (polygon) =>
          `<div style="width: ${showSideBySide ? "50%" : "100%"};">${
            polygon.stats.hsi
          }</div>`
      )
      .join("") +
    `</div></div>`
  );
};

const generateChartImage = async (aoisum, plan1sum, plan2sum, plan3sum) => {
  const chartCanvas = document.createElement("canvas");
  chartCanvas.width = 400;
  chartCanvas.height = 300;

  document.body.appendChild(chartCanvas);

  const ctx = chartCanvas.getContext("2d");
  new Chart(ctx, {
    type: "bar",
    data: {
      labels: ["AOI", "Plan 1", "Plan 2", "Plan 3"],
      datasets: [
        {
          label: "HSI", // Added label here
          data: [aoisum, plan1sum, plan2sum, plan3sum],
          backgroundColor: ["#8884d8", "#82ca9d", "#ffc658", "#ff7300"],
          borderColor: ["#8A2BE2", "#98FB98", "#FFD700", "#FF8C00"],
          borderWidth: 1,
        },
      ],
    },
    options: {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 20 // Set the font size for the x-axis labels
            }
          }
        },
        y: {
          ticks: {
            font: {
              size: 20 // Set the font size for the y-axis labels
            }
          }
        }
      },
    },
  });

  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for chart to render

  const chartImageSrc = chartCanvas.toDataURL("image/png");

  document.body.removeChild(chartCanvas);

  return chartImageSrc;
};


const createLabeledSection = (labelText, contentText) => {
  const section = document.createElement("div");
  section.style.display = "flex";
  section.style.marginBottom = "10px";

  const label = document.createElement("div");
  label.textContent = labelText;
  label.style.backgroundColor = "black";
  label.style.color = "white";
  label.style.padding = "5px 10px";
  label.style.flex = "0 0 120px";
  label.style.marginRight = "10px";

  const content = document.createElement("div");
  content.textContent = contentText;
  content.style.backgroundColor = "#e0e0e0";
  content.style.padding = "10px";
  content.style.flex = "1";

  section.appendChild(label);
  section.appendChild(content);

  return section;
};

const createTable = (aoisum,plan1sum,plan2sum,plan3sum)=> {
  const tableHTML = `
  <table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
    <thead>
      <tr>
        <td colspan="2" style="padding: 8px; border: 1px solid #ddd; text-align: left;">建設前</td>
        <td style="padding: 8px; border: 1px solid #ddd; text-align: left;">${aoisum}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td rowspan="3" style="padding: 8px; border: 1px solid #ddd;">建設後</td>
        <td style="padding: 8px; border: 1px solid #ddd;">案1</td>
        <td style="padding: 8px; border: 1px solid #ddd; text-align: left;">${plan1sum}</td>
      </tr>
      <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">案2</td>
        <td style="padding: 8px; border: 1px solid #ddd; text-align: left;">${plan2sum}</td>
      </tr>
      <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">案3</td>
        <td style="padding: 8px; border: 1px solid #ddd; text-align: left;">${plan3sum}</td>
      </tr>
    </tbody>
  </table>
`;
return tableHTML
}

const createHeader = (projectName, currentDate) => {
  const header = document.createElement("div");
  header.style.display = "flex";
  header.style.justifyContent = "space-between";
  header.style.alignItems = "center";
  header.style.marginBottom = "3px";

  const projectNameHeader = document.createElement("h3");
  projectNameHeader.textContent = projectName;
  projectNameHeader.style.color = "#333";
  projectNameHeader.style.marginBottom = "0";
  projectNameHeader.style.textAlign = "center";
  projectNameHeader.style.flexGrow = "1";

  const dateHeader = document.createElement("h4");
  dateHeader.textContent = currentDate;
  dateHeader.style.color = "#333";
  dateHeader.style.marginRight = "10px";
  dateHeader.style.whiteSpace = "nowrap";
  dateHeader.style.flexShrink = "0";

  header.appendChild(projectNameHeader);
  header.appendChild(dateHeader);

  return header;
};


const addStatsTableToPdf = (plan, pdfContent) => {
  const statsTablePage = document.createElement("div");
  pdfContent.appendChild(statsTablePage);

  // const statsTableHtml = template
  //   .replace("{title}", `${plan.name} Stats Table`)
  //   .replace("{content}", getStatsTableHtml(plan.polygonsData));

  // statsTablePage.innerHTML = statsTableHtml;
};



export {
  calculateRGBColor,
  createHeader,
  addBufferPolygonToMap,
  getFillColor,
  addPolygonsToMap,
  addPageToPdf,
  getStatsTableHtml,
  addStatsTableToPdf,
  addPolygonOutlineToMap,
  handleMapMove,
  createMap,
  generateChartImage,
  createLabeledSection,
  createTable,
  addBufferPolygonToBufferMap,
  getCurrentDate,
  getTimestampedFilename,
  addPopupToMap,
  processBufferArray,
  getCenterOfPolygon
};
