/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams } from "react-router-dom";
import UploadGeoTIFFDialog from "../common/GeotiffDialogBox";

import {
  updateProject,
  createProject,
  fetchCategories,
  batchModeling,
  fetchModels,
  getResult,
  checkResult,
} from "../../utils/carto";

// Import FileSelectDialog component
import FileSelectDialog from "./FileSelectDialog";
import MapTabs from "../common/MapTabs";
import { setDataChanged } from "../../redux/actions";
import { useDispatch } from "react-redux";
import io from "socket.io-client"; // Import the socket.io-client library
import DonutChart from "../common/DonutChart";
import SelectV2 from "react-select";

const ProjectEditForm = ({
  projects,
  method,
  onProjectDataChange,
  setSelectedTab,
  plan,
  langData,
  buttonState,
  memoizedFetchResult,
  setResultButton
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialProject = {
    name: "",
    description: "",
    source: "",
  };
  const [project, setProject] = useState(initialProject);
  const [errors, setErrors] = useState({});

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);

  const [saveAndRunButtonDisabled, setSaveAndRunButtonDisabled] =
    useState(false);
  const [hoverMessage, setHoverMessage] = useState("");

  const [uploadSourceTable, setUploadSourceTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [sourceStatus, setSourceStatus] = useState(null);
  const [componentMounted, setComponentMounted] = useState(false);
  const [modelOptions, setModelOptions] = useState([]); // State for dropdown values

  const [geoTIFFDialogOpen, setGeoTIFFDialogOpen] = useState(false);
  
  const [selectedModels, setSelectedModels] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const { projectId } = useParams();
  const fetchCategoryOptions = async () => {
    try {
      const options = await fetchCategories();
      setCategoryOptions(options);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    // ... (existing code)

    if (projects && (projects.geom === null || projects.geom === undefined)) {
      setSaveAndRunButtonDisabled(true);
      setHoverMessage("Please draw AOI for modeling");
    } else {
      setSaveAndRunButtonDisabled(false);
      setHoverMessage("");
    }

    // ... (existing code)
  }, [projects]);

  useEffect(() => {
    const fetchModelData = async () => {
      try {
        const models = await fetchModels();
        // Remove duplicates based on model_id
        const uniqueModels = Array.from(
          new Set(models.data.map((model) => model.model_id))
        ).map((model_id) =>
          models.data.find((model) => model.model_id === model_id)
        );

        setModelOptions(uniqueModels);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModelData();
  }, []);

 

  useEffect(() => {
    setComponentMounted(true);
    // Execute additional logic on component mount if needed

    return () => {
      // Cleanup logic if needed
    };
  }, []);

  

// Inside the useEffect for setting selectedModels in ProjectEditForm component
useEffect(() => {
  if (projects) {
    // console.log('pro', projects);
    setProject({
      name: projects.name,
      description: projects.description || "",
      source: projects.source || "",
    });

    if (projects.model !== undefined || null) {
      const parsedModel = JSON.parse(projects.model) || {};
      
      const selectedModelsForDropdown = [];

      for (let i = 0; i < 1; i++) {
        const selectedModelIds = parsedModel[i] || [];
        // Add a check to verify if selectedModelIds is an array before using includes
        if (Array.isArray(selectedModelIds)) {
          const selectedModelsForItem = modelOptions.filter((model) =>
            selectedModelIds.includes(model.model_id)
          );
          selectedModelsForDropdown.push(...selectedModelsForItem);
        }
      }

      setSelectedModels(selectedModelsForDropdown);
    }
  }
}, [projects, modelOptions]);


  const [socketData, setSocketData] = useState(null);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
    socket.on("jobStatus", (data) => {
      // console.log('Received job status:', data);

      // Extract the projectId from the received message payload
      const receivedProjectId = data.projectId;

      // Find the project from the list using the received projectId
      const foundProject = projects.cartodb_id === receivedProjectId;

      if (foundProject) {
        setSocketData(data);
        setSaveAndRunButtonDisabled(true);
        setResultButton(true);
        // setHoverMessage('Modeling is in process...');
      }

      if (data.completed === data.total) {
        setSaveAndRunButtonDisabled(false);
        memoizedFetchResult();
        setResultButton(false);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [projects, socketData]);



  useEffect(() => {
    fetchCategoryOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGeoTIFFUploadClick = () => {
    setGeoTIFFDialogOpen(true);
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBackClick = () => {
    dispatch(setDataChanged(true));
    navigate("/");
  };

  const handleReportClick = () => {
    const projectId = projects.cartodb_id; // Assuming `cartodb_id` is the correct property for the project ID
    navigate(`/report/${projectId}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!project.name || !project.name.trim()) {
      validationErrors.name = "Name is required";
    }

    setErrors(validationErrors);
    setIsLoading(true); // Start loading

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Convert selectedModels to the desired format
        const formattedModel = JSON.stringify(
          selectedModels.reduce((acc, model) => {
            for (let i = 0; i < 4; i++) {
              acc[i] = acc[i] || [];
              acc[i].push(model.model_id);
            }
            return acc;
          }, {})
        );

        // Update the project object with the formatted model
        const updatedProject = { ...project, model: formattedModel };

        if (method === "put") {
          const response = await updateProject(
            projects.cartodb_id,
            updatedProject
          );
          if (response.status === 200) {
            setSuccessMessage("Request successful"); // Set success message
            // navigate('/');
          } else {
            console.error("Error updating record:", response.data);
          }
        } else if (method === "post") {
          const response = await createProject(updatedProject);
          if (response.status === 200) {
            if (onProjectDataChange) {
              onProjectDataChange(response.data.data);
            }
            setSuccessMessage("Request successful"); // Set success message
          }
        }
      } catch (error) {
        setSuccessMessage("Request Failed Project Already Exists"); // Set success message
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setIsLoading(false); // Stop loading if there are validation errors
    }
  };

  const handleModelChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      const updatedModels = {};
      for (let i = 0; i < 4; i++) {
        const selectedModelsForItem = selectedOptions
          .filter((model) => model.model_id !== undefined) // Filter out any undefined values
          .slice(0, 3) // Take the first 3 selected models
          .map((model) => model.model_id);

        updatedModels[i] = selectedModelsForItem;
      }

      setSelectedModels(selectedOptions);
      setWarningMessage(""); // Clear warning message if within the limit
    } else {
      setWarningMessage("Maximum 3 options can be selected."); // Set warning message
    }

    // Check if more than three options are selected
  };

  const handleSaveAndRun = async (e) => {
    try {
      e.preventDefault();

      // Perform the save action here
      await handleSubmit(e); // Call the existing handleSubmit function

      const { cartodb_id, model } = projects;

      // Convert the existing model to the desired format
      const parsedModel = JSON.parse(model) || {};
      const formattedModel = Object.keys(parsedModel).map((key) => {
        const modelIds = parsedModel[key] || [];
        return { [key]: modelIds };
      });

      const requestData = {
        cartodbId: cartodb_id,
        model: JSON.stringify(formattedModel),
      };

      await batchModeling(requestData);
      // console.log('Save and Run action triggered'); // Placeholder for actual action
    } catch (error) {
      // Handle the error gracefully without breaking the app
      console.log("An error occurred:", error);
    }
  };

  return (
    <div style={{ padding: "6px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ margin: "0", flexGrow: 1 }}>{projects.name}</h2>

        <Button
          onClick={handleBackClick}
          variant="text"
          startIcon={<ArrowBackIcon />}
          style={{ marginRight: "10px" }}
        >
          {langData.back}
        </Button>
      </div>

      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label={langData.name}
          value={project.name || ""} // Set a default value if undefined
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          error={Boolean(errors.name)}
          helperText={errors.name}
        />

        <TextField
          name="description"
          label={langData.description}
          value={project.description || ""} // Set a default value if undefined
          onChange={handleInputChange}
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel shrink htmlFor="category-label">
            {langData.source}
          </InputLabel>
          <Select
            name="source"
            value={
              categoryOptions.includes(project.source) ? project.source : ""
            }
            onChange={handleCategoryChange}
            inputProps={{ id: "category-label" }}
            required={!uploadSourceTable}
            disabled={uploadSourceTable}
          >
            <MenuItem value="">
              <em>Select a source</em>
            </MenuItem>
            {categoryOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel shrink htmlFor="model-label">
          {langData.model}
          </InputLabel>
          <SelectV2
            name="model"
            value={selectedModels}
            onChange={handleModelChange}
            isMulti // Enable multiselect
            options={modelOptions} // Pass the model options
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.model_id}
          />
        </FormControl>
        {warningMessage && (
          <Typography variant="caption" color="error">
            {warningMessage}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress color="primary" /> // Show loader while isLoading is true
        ) : (
          successMessage && (
            <Snackbar
              open={!!successMessage}
              autoHideDuration={2000}
              onClose={() => setSuccessMessage("")}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              message={successMessage}
            />
          )
        )}
        {/* Move the Save button below the toggle button */}

        {!Array.isArray(projects) && (
          <MapTabs setSelectedTab={setSelectedTab} project={projects} langData ={langData} />
        )}

        {/* Move the Save button below the MapTabs */}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Button type="submit" variant="contained" color="primary">
              {langData.save}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={saveAndRunButtonDisabled}
              title={hoverMessage}
              onClick={handleSaveAndRun}
            >
              {langData.saveAndRun}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={buttonState}
              onClick={handleReportClick}
            >
              {langData.report}
            </Button>
          </div>

          {socketData && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {hoverMessage && (
                <Typography variant="caption" color="error">
                  {hoverMessage}
                </Typography>
              )}
              {socketData.completed === socketData.total ? (
                // Display a tick mark icon in green color when socketData.completed and socketData.total are equal
                <CheckCircleIcon
                  style={{ color: "green", marginRight: "5px" }}
                />
              ) : (
                // Display DonutChart when socketData.completed and socketData.total are not equal
                <DonutChart
                  progress={socketData.completed}
                  fail={socketData.failureCnt}
                  total={socketData.total}
                />
              )}
              <Typography>{`${socketData.completed}/${socketData.total}`}</Typography>
            </div>
          )}
        </div>
      </form>

      <UploadGeoTIFFDialog
        open={geoTIFFDialogOpen}
        onClose={() => setGeoTIFFDialogOpen(false)}
        planId={plan}
      />
    </div>
  );
};

export default ProjectEditForm;
