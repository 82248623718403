/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import AppRouter from "./AppRouter";
import MapContextProvider from "./Context/MapContext";
import { ProjectsProvider } from "./Context/ProjectContext";
import { Provider } from "react-redux";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import store from "./redux/store";
import { CircularProgress } from "@mui/material";
import { Hub } from "aws-amplify/utils";
import { getCognitoUser } from "./utils/carto";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("Initializing the Application,Please Wait..")
  const [loading, setLoading] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [isEnabled, setIsEnabled] = useState(() => {
    const storedIsEnabled = localStorage.getItem("isEnabled");
    return storedIsEnabled === "true";
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    const storedIsAdmin = localStorage.getItem("isAdmin");
    return storedIsAdmin === "true";
  });

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(()=> {
    localStorage.setItem("isEnabled", "true");
    localStorage.setItem("isAdmin", "true");
  },[])

  const printAccessTokenAndIdToken = async () => {
    try {
      const session = await fetchAuthSession(); // Fetch the authentication session
      localStorage.setItem(
        "accessToken",
        session?.tokens?.accessToken.toString()
      );
      reloadForLocalStorageSetting()
      
    } catch (e) {
      console.log(e);
    }
  };

  const reloadForLocalStorageSetting = ()=> {
    setLoadingMessage('Configuring the Application')
    window.location.reload();
  }

  useEffect(() => {
    const authListener = ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          setIsUserAuthenticated(true);
          printAccessTokenAndIdToken();
          fetchUserData();
          break;
        case "signedOut":
          setIsUserAuthenticated(false);
          localStorage.removeItem("isEnabled");
          localStorage.removeItem("isAdmin");
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);
    fetchUserData(); // Ensure data is fetched when the app initializes

    return () => {
      // Hub.remove("auth", authListener);
    };
  }, []);

  const fetchCurrentUser = async (username) => {
    try {
      const attributes = await getCognitoUser(username);
      return attributes.UserAttributes;
    } catch (error) {
      console.error("Error fetching Cognito user:", error);
      return [];
    }
  };

  const fetchUserData = async () => {
    setLoading(true);
    setShouldRender(false);
    try {
      const user = await getCurrentUser();
      const userAttributes = await fetchCurrentUser(user?.username);
      setCurrentUser({ ...user, attributes: userAttributes });
      const isEnabledValue =
        userAttributes.find((attr) => attr.Name === "custom:isEnabled")
          ?.Value === "true";
      const isAdminValue = userAttributes.find(
        (attr) => attr.Name === "custom:isAdmin"
      )?.Value === "true";

      if (isEnabledValue !== undefined) {
        setIsEnabled(isEnabledValue);
        localStorage.setItem("isEnabled", isEnabledValue);
      }

      if (isAdminValue !== undefined) {
        setIsAdmin(isAdminValue);
        localStorage.setItem("isAdmin", isAdminValue);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setShouldRender(true);
      setLoading(false); // Set loading to false after fetchUserData completes
    }
  };

  return (
    <Provider store={store}>
      <MapContextProvider>
        <ProjectsProvider>
          {loading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              flexDirection: 'column'
            }}>
              <CircularProgress />
              <div style={{ marginTop: '20px', fontSize: '16px', color: '#555' }}>
              {loadingMessage}
              </div>
            </div>
          ) : shouldRender ? (
            <AppRouter
              setIsEnabled={setIsEnabled}
              loading={loading}
              isAdmin={isAdmin}
              isEnabled={isEnabled}
            />
          ) : null}
        </ProjectsProvider>
      </MapContextProvider>
    </Provider>
  );
  
}

export default App;
