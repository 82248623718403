/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import maplegendImage from "../resources/maplegend.png";
import CustomMapComponent from "../Components/Map/CutomMap";

import chartImageSource from "../resources/Screenshot 2024-05-18 082423.png";
import {
  getResult,
  getProject,
  fetchModels,
  getBufferCircle,
  fetchTileLayer,
  getAoi250Data,
} from "../utils/carto";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
// import MapboxCompare from "mapbox-gl-compare";
import {
  addPolygonOutlineToMap,
  addPolygonsToMap,
  getFillColor,
  generateChartImage,
  addStatsTableToPdf,
  getStatsTableHtml,
  processBufferArray,
  getCenterOfPolygon,
  addBufferPolygonToBufferMap,
  createHeader,
  createTable,
  createLabeledSection,
  getCurrentDate,
  getTimestampedFilename,
  addPopupToMap,
} from "../utils/functions";
import {
  IconLayoutSidebarLeftExpand,
  IconLayoutSidebarRightExpand,
} from "@tabler/icons-react";
import {
  setAOIPolyDataInStore,
  setPlan1PolyDataInStore,
  setPlan2PolyDataInStore,
  setPlan3PolyDataInStore,
  setAOISumInStore,
  setPlan1SumInStore,
  setPlan2SumInStore,
  setPlan3SumInStore,
} from "../redux/actions";
import { calculateRGBColor } from "../utils/functions";

// Import your fictional Stats
import Stats from "../Components/common/Stats";
import IconButton from "@mui/material/IconButton";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "perfect-scrollbar/css/perfect-scrollbar.css"; // Import the styles
import PerfectScrollbar from "perfect-scrollbar";
import CircularProgress from "@mui/material/CircularProgress";
import { LinearProgress } from "@mui/material";
import html2pdf from "html2pdf.js";
import mapboxgl, { ScaleControl } from "mapbox-gl";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapIdName = ({ id }) => {
  return (
    <div
      style={{
        fontSize: "12px",
        position: "absolute",
        top: "0px",
        color: "white",
        left: "0px",
        zIndex: 1000,
        backgroundColor: "#1976D2",
        padding: "5px",
        borderRadius: "0px",
      }}
    >
      {id}
    </div>
  );
};

const ReportPage = ({ langData }) => {
  const center = [139.8127, 35.6729];
  const zoom = 10;
  const [aoisum, setAoiSum] = useState(null);
  const [editorData, setEditorData] = useState({
    1: "",
    2: "",
    3: "",
  });

  const mapAoiRef = useRef(null);
  const mapPlan1Ref = useRef(null);
  const mapPlan2Ref = useRef(null);
  const mapPlan3Ref = useRef(null);
  const [aoiPolygon, setAoiPolygon] = useState();
  const [projDescription, setProjectDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [plan1sum, setPlan1Sum] = useState(null);
  const [aoiBufferCoords, setAoiBufferCoords] = useState();
  const [planBufferCoords, setPlanBufferCoords] = useState();
  const selectedBasemap = useSelector((state) => state.selectedBasemap);
  const isMounted = useRef(true);
  const [plan2sum, setPlan2Sum] = useState(null);
  const [plan3sum, setPlan3Sum] = useState(null);
  const dispatch = useDispatch();
  const [selectedModelId, setSelectedModelId] = useState(0);
  const [queryResult, setQueryResult] = useState();
  const [polygonData, setPolygonData] = useState(null);
  const [projectName, setProjectName] = useState();
  const [aoiPolyData, setAoiPolyData] = useState(null);
  const [aoi250PolyData, setAoi250PolyData] = useState(null);
  const [plan1PolyData, setPlan1PolyData] = useState(null);
  const [plan2PolyData, setPlan2PolyData] = useState(null);
  const [plan3PolyData, setPlan3PolyData] = useState(null);
  const [model, setModel] = useState(null);
  const [modelArray, setModelArray] = useState([]);
  const [isFormCollapsed, setIsFormCollapsed] = useState(true); // Set default state to closed
  const { projectId } = useParams();
  const scrollbarRef = useRef(null);
  const [movementTriggered, setMovementTriggered] = useState(false);
  const popupRef = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
  );

  var movement = false;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getProject(projectId)
      .then((response) => {
        const modelString = response.data[0].model;
        if (response.data) {
          setEditorData({
            1: response.data[0].plan1info || "",
            2: response.data[0].plan2info || "",
            3: response.data[0].plan3info || "",
          });
        }
        if (response?.data[0]?.geom) {
          setAoiPolygon(response.data[0].geom);
        }
        setModel(modelString);
        
        const modelIdForResult = findFirstCommonElement(modelString)
     
        setSelectedModelId(modelIdForResult)
        setProjectName(response.data[0].name);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  function findFirstCommonElement(obj) {
    // Parse the input object if it's a string
    if (typeof obj === 'string') {
        obj = JSON.parse(obj);
    }
    // Convert the object values to an array of arrays
    const arrays = Object.values(obj);
    // Get the first array as the base for comparison
    const baseArray = arrays[0];
    // Loop through each element in the base array
    for (let element of baseArray) {
        // Check if the element is present in all other arrays
        if (arrays.every(array => array.includes(element))) {
            return element; // Return the first common element found
        }
    }
    return null; // Return null if no common element is found
}

  const useResizeObserver = (ref, callback) => {
    useEffect(() => {
      const observer = new ResizeObserver(callback);
      if (ref.current instanceof Element) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current instanceof Element) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, callback]);
  };

  const handleModelSelectionChange = (modelId) => {
    setSelectedModelId(modelId);
  };

  const handleResize = () => {
    if (mapAoiRef.current) mapAoiRef.current.resize();
    if (mapPlan1Ref.current) mapPlan1Ref.current.resize();
    if (mapPlan2Ref.current) mapPlan2Ref.current.resize();
    if (mapPlan3Ref.current) mapPlan3Ref.current.resize();
  };

  // Apply ResizeObserver using custom hook
  useResizeObserver(mapAoiRef, handleResize);
  useResizeObserver(mapPlan1Ref, handleResize);
  useResizeObserver(mapPlan2Ref, handleResize);
  useResizeObserver(mapPlan3Ref, handleResize);

  useEffect(() => {
    if (scrollbarRef.current) {
      // Initialize the PerfectScrollbar when the component mounts
      const ps = new PerfectScrollbar(scrollbarRef.current, {
        wheelPropagation: true,
      });

      // Cleanup when the component unmounts
      return () => {
        ps.destroy();
      };
    }
  }, []);

  useEffect(() => {
    // Fetch modelsArray and update state
    fetchModels()
      .then((models) => {
        setModelArray(models.data);
      })
      .catch((error) => {
        console.error("Error fetching models:", error);
      });
  }, [projectId]);

  useEffect(() => {
    // Fetch modelsArray and update state
    const type = "aoi";
    getBufferCircle(projectId, type)
      .then((res) => {
        setAoiBufferCoords(res.data);
      })
      .catch((error) => {
        console.error("Error fetching aoi buffer:", error);
      });
  }, [projectId]);

  useEffect(() => {
    // Fetch modelsArray and update state
    const type = "plan";
    getBufferCircle(projectId, type)
      .then((res) => {
        setPlanBufferCoords(res.data);
      })
      .catch((error) => {
        console.error("Error fetching plan buffer:", error);
      });
  }, [projectId]);

  useEffect(() => {
    getAoi250Data(projectId)
      .then((response) => {
        const data = response.data.data; // Access the nested 'data' array

        if (Array.isArray(data)) {
          // Ensure 'data' is an array
          const polygonsData = data.map((item) => {
            const { geom, fid, HSI_Range } = item;
            const uniqueId = uuidv4();
            return {
              coordinates: geom ? geom.coordinates : [], // Handle potential null 'geom'
              stats: {
                id: uniqueId,
                type: geom ? geom.type : null,
                fid,
                hsi: HSI_Range,
              },
            };
          });

          setAoi250PolyData(polygonsData);
        } else {
          console.error("Expected data to be an array, but it was not.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [projectId]);

  useEffect(() => {
    // if (!isMounted.current) return;
    getResult(projectId, selectedModelId)
      .then((response) => {
        setQueryResult(response.data);
        const planIdMap = {
          0: setAoiSum,
          1: setPlan1Sum,
          2: setPlan2Sum,
          3: setPlan3Sum,
        };

        const filteredPolygonsData = response.data.filter(
          (polygon) => polygon.model_id === selectedModelId
        );

        const polygonsData = filteredPolygonsData.map((item) => {
          const { plan_id, geom, n_v1, n_v2, n_v3, fid, hsi, cartodb_id } =
            item;

          const sumsData = response.data.reduce((acc, item) => {
            const { plan_id, sum } = item;
            const setSum = planIdMap[plan_id];
            if (setSum) setSum(sum);
            acc[`${plan_id}sum`] = sum;
            return acc;
          }, {});

          // Dispatch new actions to store sums
          dispatch(setAOISumInStore(sumsData["0sum"]));
          dispatch(setPlan1SumInStore(sumsData["1sum"]));
          dispatch(setPlan2SumInStore(sumsData["2sum"]));
          dispatch(setPlan3SumInStore(sumsData["3sum"]));

          const uniqueId = uuidv4();
          return {
            coordinates: geom.coordinates,
            stats: {
              id: uniqueId,
              type: geom.type,
              nv1: n_v1,
              nv2: n_v2,
              nv3: n_v3,
              fid,
              hsi,
              name: `Polygon ${cartodb_id}`,
              planNumber: plan_id,
            },
          };
        });

        setPolygonData(polygonsData);

        const aoiPolygons = polygonsData.filter(
          (item) => item.stats.planNumber === 0
        );
        setAoiPolyData(aoiPolygons);
        dispatch(setAOIPolyDataInStore(aoiPolygons));

        const plan1Polygons = polygonsData.filter(
          (item) => item.stats.planNumber === 1
        );
        setPlan1PolyData(plan1Polygons);
        dispatch(setPlan1PolyDataInStore(plan1Polygons));

        const plan2Polygons = polygonsData.filter(
          (item) => item.stats.planNumber === 2
        );
        setPlan2PolyData(plan2Polygons);
        dispatch(setPlan2PolyDataInStore(plan2Polygons));

        const plan3Polygons = polygonsData.filter(
          (item) => item.stats.planNumber === 3
        );
        setPlan3PolyData(plan3Polygons);
        dispatch(setPlan3PolyDataInStore(plan3Polygons));
      })
      .catch((error) => {
        console.error("Error fetching data for projectId:", projectId, error);
      });
  }, [selectedModelId, projectId, dispatch]);

  function getModelInfo(model_id) {
    for (let i = 0; i < modelArray.length; i++) {
      if (modelArray[i].model_id === model_id) {
        return { name: modelArray[i].name, title: modelArray[i].title };
      }
    }
    return null; // return null if model_id is not found
  }

  useEffect(() => {
    getProject(projectId)
      .then((response) => {
        const modelString = response.data[0].model;
        if (response.data) {
          const projDescription = response.data[0].description;
          setProjectDescription(projDescription);
          setEditorData({
            1: response.data[0].plan1info || "",
            2: response.data[0].plan2info || "",
            3: response.data[0].plan3info || "",
          });
        }
        if (response?.data[0]?.geom) {
          setAoiPolygon(response.data[0].geom);
        }
        setModel(modelString);
        const parsedModelString = JSON.parse(modelString);
        const modelIds = Object.values(parsedModelString).flat();

        setProjectName(response.data[0].name);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleBackButtonClick = () => {
    window.history.back(); // Simulate a browser back button click
  };

  const handleDownloadButtonClick = async () => {
    setLoading(true);
    const plans = [
      { name: "AOI", polygonsData: aoiPolyData, langName : langData.aoi},
      { name: "AOI250", polygonsData: aoi250PolyData, langName : langData.aoi250 },
      { name: "Plan 1", polygonsData: plan1PolyData, langName : langData.plan1 },
      { name: "Plan 2", polygonsData: plan2PolyData, langName : langData.plan2 },
      { name: "Plan 3", polygonsData: plan3PolyData, langName : langData.plan3  },
    ];

    const bufferArray = [
      { name: "2000", polygonsData: aoiBufferCoords },
      { name: "250", polygonsData: planBufferCoords },
    ];

    const pdfOptions = {
      margin: 0,
      filename: getTimestampedFilename(),
      image: { type: "jpeg", quality: 2.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    const template = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Responsive PDF Template</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          .container {
            text-align: center;
            padding: 11px;
            margin-bottom: 0px;
          }
          .content {
            margin-bottom: 10px;
          }
          @media screen and (max-width: 768px) {
            .container {
              padding: 5px;
            }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="content">{content}</div>
        </div>
      </body>
      </html>
    `;

    const pdfContent = document.createElement("div");
    document.body.appendChild(pdfContent);

    await addFirstPageToPdf(bufferArray, pdfContent);

    const downloadImagesToPdf = async () => {
      const center = getCenterOfPolygon(aoiPolygon.coordinates[0]);
      for (let i = 0; i < plans.length; i++) {
        const plan = plans[i];
        const map = new mapboxgl.Map({
          container: document.createElement("div"),
          style: `mapbox://styles/mapbox/${selectedBasemap}`,
          center: center,
          zoom: 9,
        });
        const scaleControl = new mapboxgl.ScaleControl({
          maxWidth: 100,
          unit: "metric",
        });
        map.addControl(scaleControl, "top-right");
        await addPageToPdf(map, plan, pdfContent, template, i);
      }
      html2pdf(pdfContent, pdfOptions);
      document.body.removeChild(pdfContent);
      setLoading(false);
    };

    downloadImagesToPdf();
  };

  const addFirstPageToPdf = async (bufferArray, pdfContent) => {
    const firstPage = document.createElement("div");
    firstPage.style.borderRadius = "10px";
    firstPage.style.padding = "0";
    firstPage.style.pageBreakAfter = "always";

    const tableContainer = document.createElement("div");
    tableContainer.innerHTML = createTable(
      aoisum,
      plan1sum,
      plan2sum,
      plan3sum
    );
    tableContainer.style.width = "100%";
    tableContainer.style.marginTop = "20px";
    tableContainer.style.padding = "20px";

    const contentContainer = document.createElement("div");
    contentContainer.style.padding = "20px";

    const header = createHeader(projectName, getCurrentDate());
    contentContainer.appendChild(header);

    const hr = document.createElement("hr");
    hr.style.margin = "10";
    hr.style.width = "calc(100% + 40px)";
    hr.style.transform = "translateX(-20px)";
    contentContainer.appendChild(hr);

    const plan1Description = editorData?.[1];
    const plan2Description = editorData?.[2];
    const plan3Description = editorData?.[3];
    const modelInfo = getModelInfo(selectedModelId);

    contentContainer.appendChild(
      createLabeledSection(`${langData.projectOverView}`, `${projDescription}`)
    );
    contentContainer.appendChild(
      createLabeledSection(`${langData.modelColumn}`, `${modelInfo.title}`)
    );
    contentContainer.appendChild(
      createLabeledSection(
        `${langData.plan1}`,
        `${removeHtmlTags(plan1Description)}`
      )
    );
    contentContainer.appendChild(
      createLabeledSection(
        `${langData.plan2}`,
        `${removeHtmlTags(plan2Description)}`
      )
    );
    contentContainer.appendChild(
      createLabeledSection(
        `${langData.plan3}`,
        `${removeHtmlTags(plan3Description)}`
      )
    );
    contentContainer.appendChild(tableContainer);
    firstPage.appendChild(contentContainer);
    pdfContent.appendChild(firstPage);

    const newPage = document.createElement("div");
    newPage.style.padding = "0";
    newPage.style.pageBreakAfter = "always";

    const newContentContainer = document.createElement("div");
    newContentContainer.style.padding = "20px";

    const newHeader = createHeader(projectName, getCurrentDate());
    newContentContainer.appendChild(newHeader);

    const newHr = document.createElement("hr");
    newHr.style.margin = "0";
    newHr.style.width = "calc(100% + 40px)";
    newHr.style.transform = "translateX(-20px)";
    newContentContainer.appendChild(newHr);

    const headingSection = document.createElement("div");
    headingSection.textContent = `${langData.targetAreaOverview}`;
    headingSection.style.backgroundColor = "white";
    headingSection.style.color = "black";
    headingSection.style.fontWeight = "bold";
    headingSection.style.padding = "5px 10px";
    headingSection.style.marginBottom = "10px";
    headingSection.style.textAlign = "center"; // Add this line to center the text
    newContentContainer.appendChild(headingSection);

    const bufferMapsContainer = document.createElement("div");
    bufferMapsContainer.style.marginBottom = "50px";

    try {
      await processBufferArray(
        bufferArray,
        bufferMapsContainer,
        selectedBasemap,
        aoiPolygon,
        langData
      );
      newContentContainer.appendChild(bufferMapsContainer);
      newPage.appendChild(newContentContainer);
      pdfContent.appendChild(newPage);
    } catch (error) {
      console.error("Error processing buffer maps:", error);
      // Handle error, possibly with UI feedback or logging
    }
  };

  const addPageToPdf = async (map, plan, pdfContent, template, index) => {
    let bufferCords;
    if (plan.name === "AOI") {
      bufferCords = aoiBufferCoords;
    } else {
      bufferCords = planBufferCoords;
    }

    // Determine if the current plan should be grouped with another on the same page
    const isAoiGroup = plan.name === "AOI" || plan.name === "AOI250";
    const isPlanGroup = plan.name === "Plan 1" || plan.name === "Plan 2";

    // Create a new page only if starting a new group
    if (
      (isAoiGroup && index % 2 === 0) ||
      (isPlanGroup && index % 2 === 0) ||
      plan.name === "Plan 3"
    ) {
      const page = document.createElement("div");
      page.style.pageBreakAfter = "always"; // Ensure each page is handled as one unit
      pdfContent.appendChild(page);

      if (index % 2 === 0) {
        const modelInfo = getModelInfo(selectedModelId);
        const header = createHeader(projectName, getCurrentDate());
        const model = document.createElement("h6");
        model.style.textAlign = "center";
        model.style.marginBottom = "0px";
        model.textContent = `${modelInfo.title}`;

        page.appendChild(header);
        page.appendChild(document.createElement("hr"));
        page.appendChild(model);
      }
    }

    // Reference to the last page added
    const lastPage = pdfContent.lastElementChild;

    await new Promise((resolve) => {
      map.on("load", () => {
        addPolygonsToMap(map, plan.polygonsData, bufferCords, aoiPolygon);
      });

      map.once("idle", async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const canvas = document.createElement("canvas");
        const aspectRatio = map.getCanvas().width / map.getCanvas().height;
        canvas.width = 800;
        canvas.height = canvas.width / aspectRatio;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(map.getCanvas(), 0, 0, canvas.width, canvas.height);

        const scaleControl = map
          .getContainer()
          .querySelector(".mapboxgl-ctrl-scale");

        if (scaleControl && scaleControl.textContent) {
          const scaleText = scaleControl.textContent;
          const scaleWidth = 100; // Increase the width of the scale control for visibility
          const scaleHeight = 30; // Increase the height of the scale control for visibility

          // Draw the scale control at a visible and non-cutting position
          const xOffset = canvas.width - scaleWidth - 20; // Position from the right edge of the canvas
          const yOffset = canvas.height - scaleHeight - 20; // Y position from the bottom edge of the canvas

          // Ensure white background for visibility
          ctx.fillStyle = "white";
          ctx.fillRect(xOffset, yOffset, scaleWidth, scaleHeight);

          // Draw border for scale
          ctx.strokeStyle = "black";
          ctx.strokeRect(xOffset, yOffset, scaleWidth, scaleHeight);

          // Text settings, larger and bold for better visibility
          ctx.fillStyle = "black";
          ctx.font = "16px Arial bold";
          ctx.fillText(
            scaleText,
            xOffset + 5, // Adjust the text position for better centering
            yOffset + 22 // Adjusting for text baseline inside the box
          );
        }

        ctx.font = "12px";
        ctx.fillStyle = "#1976D2";
        const textPadding = 5;
        const textWidth = ctx.measureText(plan.langName).width + 2 * textPadding;
        const textHeight = 20;

        ctx.fillRect(10, 10, textWidth, textHeight);
        ctx.fillStyle = "white";
        ctx.fillText(plan.langName, 15, 25);

        const mapContainerDiv = document.createElement("div");
        mapContainerDiv.style.display = "flex";
        mapContainerDiv.style.alignItems = "center";
        mapContainerDiv.style.marginBottom = "20px";
        mapContainerDiv.style.marginTop = "20px";
        mapContainerDiv.style.pageBreakInside = "avoid"; // Prevent breaking inside the div

        const mapLegend = document.createElement("img");
        mapLegend.src = maplegendImage;
        mapLegend.style.height = "360px";
        mapLegend.style.marginRight = "10px";

        const mapImage = document.createElement("img");
        mapImage.src = canvas.toDataURL("image/jpeg");
        mapImage.style.width = "75%";
        mapImage.style.height = "360px";
        mapImage.style.display = "block";
        mapImage.style.margin = "0 auto";

        mapContainerDiv.appendChild(mapLegend);
        mapContainerDiv.appendChild(mapImage);

        const pageHtml = template.replace(
          "{content}",
          mapContainerDiv.outerHTML
        );

        lastPage.innerHTML += pageHtml;

        if (plan.name === "Plan 3") {
          const chartImageSrc = await generateChartImage(
            aoisum,
            plan1sum,
            plan2sum,
            plan3sum
          );
          const chartImage = document.createElement("img");
          chartImage.src = chartImageSrc;
          chartImage.style.width = "80%";
          chartImage.style.maxHeight = "400px";
          chartImage.style.objectFit = "contain";
          chartImage.style.display = "block";
          chartImage.style.marginTop = "20px";
          chartImage.style.marginRight = "20px";
          chartImage.style.marginLeft = "20px";

          lastPage.appendChild(chartImage);
        }

        map.remove();
        resolve();
      });
    });
  };

  function removeHtmlTags(str) {
    return str.replace(/<[^>]*>/g, "");
  }

  const toggleFormCollapse = () => {
    setIsFormCollapsed((prev) => !prev);
  };

  const handleMapMove = (mapRef, newCenter, newZoom) => {
    const duration = 0;
    if (!movement && mapRef) {
      const targetRefs = [mapAoiRef, mapPlan1Ref, mapPlan2Ref, mapPlan3Ref];
      const currentIndex = targetRefs.findIndex((ref) => ref === mapRef);
      if (currentIndex !== -1) {
        movement = true;

        for (let i = 0; i < targetRefs.length; i++) {
          if (i !== currentIndex) {
            if (targetRefs[i].current) {
              targetRefs[i].current.easeTo({
                center: newCenter,
                zoom: newZoom,
                duration,
              });
            }
          }
        }

        movement = false;
      }
    }
  };

  const createMap = (containerId, initialCenter, initialZoom, mapRef) => {
    if (containerId) {
      const map = new mapboxgl.Map({
        container: containerId,
        style: `mapbox://styles/mapbox/${selectedBasemap}`,
        center: initialCenter,
        zoom: initialZoom,
      });

      mapRef.current = map;

      const navControl = new mapboxgl.NavigationControl();
      mapRef.current.addControl(navControl, "bottom-right");
      mapRef.current.addControl(new mapboxgl.ScaleControl(), "bottom-left");

      map.on("move", () => {
        const newCenter = map.getCenter().toArray();
        const newZoom = map.getZoom();
        handleMapMove(mapRef, newCenter, newZoom);
      });

      return map;
    }
  };

  useEffect(() => {
    const mapAoi = createMap("map-aoi", center, zoom, mapAoiRef);
    if (mapAoi) {
      if (
        aoiPolyData &&
        Array.isArray(aoiPolyData) &&
        aoiPolyData.length > 0 &&
        aoiBufferCoords?.length > 0 &&
        aoiPolygon
      ) {
        addPolygonsToMap(mapAoi, aoiPolyData, aoiBufferCoords);
        addPolygonOutlineToMap(mapAoi, aoiPolygon);

        aoiPolyData.forEach((polygon) => {
          const { stats } = polygon;
          const popupContent = `<p>HSI: ${stats.hsi}</p>`;
          addPopupToMap(
            mapAoi,
            `polygon-layer-${stats.id}`,
            popupContent,
            popupRef
          );
        });
      }
    }
  }, [selectedModelId, aoiPolyData, selectedBasemap]);

  useEffect(() => {
    const mapPlan1 = createMap("map-plan1", center, zoom, mapPlan1Ref);

    if (mapPlan1) {
      if (
        plan1PolyData &&
        Array.isArray(plan1PolyData) &&
        plan1PolyData.length > 0 &&
        aoiPolygon
      ) {
        addPolygonsToMap(mapPlan1, plan1PolyData, planBufferCoords);
        addPolygonOutlineToMap(mapPlan1, aoiPolygon);
        plan1PolyData.forEach((polygon) => {
          const { stats } = polygon;
          const popupContent = `<p>HSI: ${stats.hsi}</p>`;
          addPopupToMap(
            mapPlan1,
            `polygon-layer-${stats.id}`,
            popupContent,
            popupRef
          );
        });
      }
    }
  }, [selectedModelId, plan1PolyData, selectedBasemap]);

  useEffect(() => {
    const mapPlan2 = createMap("map-plan2", center, zoom, mapPlan2Ref);
    if (mapPlan2) {
      if (
        plan2PolyData &&
        Array.isArray(plan2PolyData) &&
        plan2PolyData.length > 0 &&
        aoiPolygon
      ) {
        addPolygonsToMap(mapPlan2, plan2PolyData, planBufferCoords);
        addPolygonOutlineToMap(mapPlan2, aoiPolygon);
        plan2PolyData.forEach((polygon) => {
          const { stats } = polygon;
          const popupContent = `<p>HSI: ${stats.hsi}</p>`;
          addPopupToMap(
            mapPlan2,
            `polygon-layer-${stats.id}`,
            popupContent,
            popupRef
          );
        });
      }
    }
  }, [selectedModelId, plan2PolyData, selectedBasemap]);

  useEffect(() => {
    const mapPlan3 = createMap("map-plan3", center, zoom, mapPlan3Ref);
    if (mapPlan3) {
      if (
        plan3PolyData &&
        Array.isArray(plan3PolyData) &&
        plan3PolyData.length > 0 &&
        aoiPolygon
      ) {
        addPolygonsToMap(mapPlan3, plan3PolyData, planBufferCoords);
        addPolygonOutlineToMap(mapPlan3, aoiPolygon);
        plan3PolyData.forEach((polygon) => {
          const { stats } = polygon;
          const popupContent = `<p>HSI: ${stats.hsi}</p>`;
          addPopupToMap(
            mapPlan3,
            `polygon-layer-${stats.id}`,
            popupContent,
            popupRef
          );
        });
      }
    }
  }, [selectedModelId, plan3PolyData, selectedBasemap]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        overflow: "auto",
        transition: "flex 0.3s ease-in-out, padding 0.3s ease-in-out",
      }}
    >
      <div
        style={{
          flex: "1 1 auto",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          gap: "2.5px",
        }}
      >
        <div>
          <div
            id="map-aoi"
            style={{ width: "100%", height: "100%" }}
            ref={mapAoiRef}
          >
            <MapIdName id={langData.aoi} />
          </div>
        </div>

        <div>
          <div
            id="map-plan1"
            style={{ width: "100%", height: "100%" }}
            ref={mapPlan1Ref}
          >
            <MapIdName id={langData.plan1} />
          </div>
        </div>

        <div>
          <div
            id="map-plan2"
            style={{ width: "100%", height: "100%" }}
            ref={mapPlan2Ref}
          >
            <MapIdName id={langData.plan2} />
          </div>
        </div>

        <div>
          <div
            id="map-plan3"
            style={{ width: "100%", height: "100%" }}
            ref={mapPlan3Ref}
          >
            <MapIdName id={langData.plan3} />
          </div>
        </div>
      </div>

      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}

      <div style={{ padding: "20px", position: "relative"}}>
        <IconButton
          style={{ position: "absolute", top: "5px", right: "0px" }}
          onClick={toggleFormCollapse}
        >
          {isFormCollapsed ? (
            <IconLayoutSidebarLeftExpand />
          ) : (
            <IconLayoutSidebarRightExpand />
          )}
        </IconButton>
        {isFormCollapsed && (
          <div className="unhighlight-stats">
            <Stats
              projectId={projectId}
              onBackButtonClick={handleBackButtonClick}
              modelString={model}
              modelArray={modelArray}
              name={projectName}
              jsonData={queryResult}
              download={handleDownloadButtonClick}
              langData={langData}
              plansDescription={editorData}
              onModelSelectionChange={handleModelSelectionChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportPage;
