/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { calculateRGBColor } from "../../utils/functions";
import { useSelector } from "react-redux";

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN;

const CustomMapComponent = ({ zoom, polygonsData, planNumber }) => {
  const mapContainerRef = useRef(null);
  const sourceRef = useRef({});
  const mapRef = useRef(null);
  const popupRef = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
  );

  const center = [139.8127, 35.6729];

  const selectedBasemap = useSelector((state) => state.selectedBasemap);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: `mapbox://styles/mapbox/${selectedBasemap}`,
        center: center,
        zoom: 10,
        pitch: 45,
        bearing: 0,
      });

      const navControl = new mapboxgl.NavigationControl();
      mapRef.current.addControl(navControl, "bottom-right");

      mapRef.current.on("load", () => {
        if (polygonsData) {
          addPolygonsToMap(mapRef.current, polygonsData);
        }
      });

      return () => {
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
      };
    }
  }, [center, zoom, polygonsData, selectedBasemap]);

  const addPolygonsToMap = (map, data) => {
    if (map.isStyleLoaded()) {
      data.forEach((polygon) => {
        const { coordinates, stats } = polygon;
        if (stats.type === "Polygon") {
          // console.log('cord', coordinates[0].length)
          addPolygonToMap(map, stats, coordinates[0]);
        } else if (stats.type === "MultiPolygon") {
          addMultipolygonToMap(map, stats, coordinates);
        }
      });
    }
  };

  const addPolygonToMap = (map, stats, coordinates) => {
    const transformedCoordinates = coordinates.map(([lng, lat]) => [lng, lat]);

    const rgbColor = calculateRGBColor(stats.hsi);

    map.addSource(`polygon-source-${stats.id}`, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [transformedCoordinates],
        },
        properties: stats,
      },
    });

    sourceRef.current[`polygon-source-${stats.id}`] = transformedCoordinates;

    map.addLayer({
      id: `polygon-layer-${stats.id}`,
      type: "fill",
      source: `polygon-source-${stats.id}`,
      paint: {
        "fill-color": rgbColor,
        "fill-opacity": 0.5,
        // "fill-outline-color": "rgba(0, 0, 0, 2)",
        "fill-antialias": true,
        "fill-translate": [0, 0],
        "fill-translate-anchor": "viewport",
      },
    });

    const bounds = new mapboxgl.LngLatBounds();
    transformedCoordinates.forEach((lngLat) => {
      bounds.extend(lngLat);
    });

    // Set a minimum zoom level
    // const minZoom = 10;
    // const currentZoom = map.getZoom();
    const padding = 20;

    map.fitBounds(bounds, {
      padding,
      zoom: 12,
    });

    // map.setZoom(10)

    map.on("mousemove", `polygon-layer-${stats.id}`, (e) => {
      map.getCanvas().style.cursor = "pointer";

      const popupContent = `<p>HSI: ${e.features[0].properties.hsi}</p>`;

      popupRef.current.setLngLat(e.lngLat).setHTML(popupContent).addTo(map);
    });

    map.on("mouseleave", `polygon-layer-${stats.id}`, () => {
      map.getCanvas().style.cursor = "";
      popupRef.current.remove();
    });
  };

  const addMultipolygonToMap = (map, stats, coordinates) => {
    const transformedCoordinates = coordinates.map((polygon) =>
      polygon.map((ring) => ring.map(([lng, lat]) => [lng, lat]))
    );

    const rgbColor = calculateRGBColor(stats.hsi);

    map.addSource(`multipolygon-source-${stats.id}`, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: transformedCoordinates,
        },
        properties: stats,
      },
    });

    sourceRef.current[`multipolygon-source-${stats.id}`] =
      transformedCoordinates;

    map.addLayer({
      id: `multipolygon-layer-${stats.id}`,
      type: "fill",
      source: `multipolygon-source-${stats.id}`,
      paint: {
        "fill-color": rgbColor,
        "fill-opacity": 0.5,
        // "fill-outline-color": "rgba(0, 0, 0, 2)",
        "fill-antialias": true,
        "fill-translate": [0, 0],
        "fill-translate-anchor": "viewport",
      },
    });

    const bounds = new mapboxgl.LngLatBounds();
    transformedCoordinates.forEach((polygon) => {
      polygon.forEach((ring) => {
        ring.forEach((lngLat) => {
          bounds.extend(lngLat);
        });
      });
    });

    // Set a minimum zoom level
    // const minZoom = 10;
    // const currentZoom = map.getZoom() ;
    const padding = 20;

    map.fitBounds(bounds, {
      padding,
      zoom: 12,
    });

    // map.setZoom(10)

    map.on("mousemove", `multipolygon-layer-${stats.id}`, (e) => {
      map.getCanvas().style.cursor = "pointer";

      const popupContent = `<p>HSI: ${e.features[0].properties.hsi}</p>`;

      popupRef.current.setLngLat(e.lngLat).setHTML(popupContent).addTo(map);
    });

    map.on("mouseleave", `multipolygon-layer-${stats.id}`, () => {
      map.getCanvas().style.cursor = "";
      popupRef.current.remove();
    });
  };

  return (
    <div
      id={`map-container-${planNumber}`}
      style={{ position: "relative", height: "100%" }}
    >
      <div
        ref={mapContainerRef}
        style={{ height: "100%", width: "100%" }}
      ></div>
      {planNumber && (
        <p
          style={{
            position: "absolute",
            top: 0,
            color: "white",
            left: 0,
            padding: "10px",
            margin: 0,
            backgroundColor: "#1976D2",
          }}
        >
          {planNumber}
        </p>
      )}
    </div>
  );
};

export default CustomMapComponent;
