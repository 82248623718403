/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePageLayout from "./Pages/HomePage";
import EditPageWithMap from "./Pages/EditPageWithMap";
import Report from "./Pages/ReportPage";
import ChartsPage from "./Pages/ChartsPage";
import Dashboard from "./Components/common/Dashboard";
import Documentation from "./Pages/Documentation";
import Navbar from "./Components/common/Navbar";
import enData from "./locales/en.json";
import jpData from "./locales/ja.json";
import { Authenticator } from "@aws-amplify/ui-react";
import { CircularProgress, Button } from "@mui/material";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);

const AppRouter = ({ setIsEnabled, loading, isAdmin, isEnabled }) => {
  const [currentLanguage, setCurrentLanguage] = useState("ja");
  const [langData, setLangData] = useState(
    currentLanguage === "en" ? enData : jpData
  );
  const [showPermissionDenied, setShowPermissionDenied] = useState(false);

  useEffect(() => {
    I18n.putVocabularies({
      en: {
        "Preferred Username": "Preferred Username",
        "Enter your Preferred Username": "Enter your Preferred Username",
      },
      ja: {
        "Preferred Username": "優先ユーザ名",
        "Enter your Preferred Username": "お好みのユーザー名を入力してください",
      },
    });

    I18n.setLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get("lang");
    if (langParam && (langParam === "en" || langParam === "ja")) {
      setCurrentLanguage(langParam);
    }
  }, []);

  useEffect(() => {
    setLangData(currentLanguage === "en" ? enData : jpData);
  }, [currentLanguage]);

  useEffect(() => {
    if (!isEnabled) {
      setTimeout(() => {
        setShowPermissionDenied(true);
      }, 1500); // 3 seconds delay
    }
  }, [isEnabled]);

  const handleReload = () => {
    window.location.reload();
  };

  const handleSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    handleReload();
  };
  return (
    <Router>
      <Authenticator
        initialState="signIn"
        loginMechanisms={["email"]}
        signUpAttributes={["email", "preferred_username"]}
      >
        {showPermissionDenied && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
              color: "#000",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>{langData.permission}</h2>
              <p>
               {langData.permissionMsg}
              </p>
              <Button variant="contained" onClick={handleSignOut}>
                {langData.signOut}
              </Button>
            </div>
          </div>
        )}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
            Loading
          </div>
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Navbar isAdmin={isAdmin} langData={langData} />
            <div
              style={{
                flex: 1,
                overflowY: "hidden",
                width: "100%",
                height: "calc(100% - 64px)",
              }}
            >
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<HomePageLayout langData={langData} />}
                />
                <Route
                  exact
                  path="/report/:projectId"
                  element={<Report langData={langData} />}
                />
                <Route
                  exact
                  path="/edit/:projectId"
                  element={<EditPageWithMap method="put" langData={langData} />}
                />
                <Route
                  exact
                  path="/create"
                  element={
                    <EditPageWithMap method="post" langData={langData} />
                  }
                />
                <Route
                  exact
                  path="/chart"
                  element={<ChartsPage langData={langData} />}
                />
                <Route
                  exact
                  path="/dashboard"
                  element={isAdmin ? <Dashboard langData={langData} /> : <Navigate to = "/"/>}
                />
                {/* <Route
                  exact
                  path="/documentation"
                  element={<Documentation />}
                /> */}
              </Routes>
            </div>
          </div>
        )}
      </Authenticator>
    </Router>
  );
};

export default AppRouter;
