import React from 'react';
import { Select, MenuItem, Box } from '@mui/material';

const BasemapSelector = ({ selectedBasemap, basemaps, onChange,langData }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ minWidth: '150px', marginRight: '10px' }}>
      <span style={{ color: 'white', marginRight: '8px' }}>{langData.baseMap}: </span>
      <Select
        value={selectedBasemap}
        onChange={onChange}
        sx={{ color: 'white' }}
      >
        {basemaps.map((basemap) => (
          <MenuItem key={basemap.id} value={basemap.id}>
            {basemap.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default BasemapSelector;
