/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { getResult } from "../../utils/carto";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MapLegend from "./MapLegend";
import ChartsDialog from "./ChartsDialog";
import DownloadIcon from "@mui/icons-material/Download";
import Menu from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import PlanDescription from "./PlanDescription";

const Section = ({ title, statistics,langData }) => (
  <div style={{ margin: "1px 0", textAlign: "center" }}>
    <h5 style={{ margin: "0" }}>{title}</h5>
    <p style={{ margin: "0", whiteSpace: "nowrap", textAlign: "left" }}>
      <strong>{langData.aoi}:</strong> {statistics.sum}
      <br />
      <strong>{langData.plan1}:</strong> {statistics.plan1sum}
      <br />
      <strong>{langData.plan2}:</strong> {statistics.plan2sum}
      <br />
      <strong>{langData.plan3}:</strong> {statistics.plan3sum}
    </p>
  </div>
);

const Stats = ({
  
  projectId,
  onBackButtonClick,
  modelString,
  modelArray,
  name,
  jsonData,
  download,
  langData,
  plansDescription,
  onModelSelectionChange
}) => {
  const [selectedModel, setSelectedModel] = useState("");
  const [filteredModelOptions, setFilteredModelOptions] = useState([]);
  const [selectedModelKey, setSelectedModelKey] = useState("");
  const [selectedId,setSelectedId] = useState(1)
  const navigate = useNavigate();
  const [sumsData, setSumsData] = useState({})
  const [isChartsDialogOpen, setIsChartsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedPlan, setExpandedPlan] = useState("");

  // console.log('sums',sum, plan1sum,plan2sum,plan3sum)
  useEffect(() => {
   
  
    if(selectedId) {
      getResult(projectId, selectedId)
      .then((response) => {
        let sum;
        let plan1sum;
        let plan2sum;
        let plan3sum;
        const planIdMap = {
          0: sum,
          1: plan1sum,
          2: plan2sum,
          3: plan3sum,
        };
        const sums = response?.data?.reduce((acc, item) => {
          const { plan_id, sum } = item;
          const setSum = planIdMap[plan_id];
          // if (setSum) setSum(sum);
          acc[`${plan_id}sum`] = sum;
          return acc;
        }, {});

        setSumsData(sums)

        
  
       
      })
      .catch((error) => {
        console.error("Error fetching data for projectId:", projectId, error);
      });
    }
    
  }, [projectId, selectedId ]);

  

  useEffect(() => {
    if (!modelString || !modelArray) {
      return;
    }
  
    try {
      const parsedModelString = JSON.parse(modelString);
      const modelIds = Object.values(parsedModelString).flat();
  
      const filterUniqueModels = (models) => {
        const uniqueModels = [];
        const seenIds = new Set();
  
        for (const model of models) {
          if (!seenIds.has(model.model_id)) {
            seenIds.add(model.model_id);
            uniqueModels.push(model);
          }
        }
  
        return uniqueModels;
      };
  
      const filteredOptions = filterUniqueModels(
        modelArray.filter((model) => modelIds.includes(model.model_id))
      );
      setFilteredModelOptions(filteredOptions);
  
      if (filteredOptions.length > 0) {
        setSelectedModel(filteredOptions[0].name);
        setSelectedModelKey(filteredOptions[0].name);
      }
    } catch (error) {
      console.error("Error parsing modelString:", error);
    }
  }, [modelString, modelArray]);

  const handleChartClick = () => {
    setIsChartsDialogOpen(true);
  };

  const handleModelChange = (event) => {
    const { value } = event.target;
   
    setSelectedModel(value);
    setSelectedModelKey(value);
    const selectedModelId = filteredModelOptions.find(
      (model) => model.name === value
    )?.model_id;
    setSelectedId(selectedModelId)
    onModelSelectionChange(selectedModelId);
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadJson = () => {
    const jsonDataString = JSON.stringify(jsonData, null, 2);
    setLoading(true);
    const blob = new Blob([jsonDataString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setLoading(false);
    handleCloseMenu();
  };

  const handleDownloadOption = (option) => {
    console.log(`Download option selected: ${option}`);
    if (option === "JSON") {
      downloadJson();
    } else if (option === "PDF") {
      download();
      handleCloseMenu();
      // Implement PDF download logic if needed
    }
  };

  const handlePlanExpand = (planTitle) => {
    setExpandedPlan(planTitle);
  };

  return (
    <div style={{ padding: 0, marginTop: 0, textAlign: "center" }}>
      {loading && <CircularProgress />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "flex-start",
        }}
      >
        <IconButton onClick={onBackButtonClick} color="primary" size="small">
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ margin: "0", marginLeft: "10px" }}>{name}</h3>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        <Button
          style={{ marginRight: "10px", fontSize: "12px" }}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadClick}
        >
          {langData.download}
        </Button>
        <Button
          style={{ fontSize: "12px" }}
          onClick={handleChartClick}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<ShowChartIcon />}
        >
          {langData.chart}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => handleDownloadOption("JSON")}>JSON</MenuItem>
          <MenuItem onClick={() => handleDownloadOption("PDF")}>PDF</MenuItem>
        </Menu>
      </div>

      <FormControl
        style={{ marginBottom: "10px", width: "100%", marginRight: "20px" }}
      >
        <InputLabel
          id="model-select-label"
          style={{ color: "#3f51b5", fontSize: "14px", marginBottom: "0px" }}
        >
          {langData.selectModel}
        </InputLabel>
        <Select
          key={selectedModelKey}
          labelId="model-select-label"
          value={selectedModel}
          onChange={handleModelChange}
          label="Select Model"
          input={<OutlinedInput label="Select Model" />}
          style={{
            backgroundColor: "#fff",
            borderRadius: "4px",
            marginTop: "-5px",
            fontSize: "12px",
          }}
        >
          {filteredModelOptions.map((model) => (
            <MenuItem key={model.model_id} value={model.name}>
              {model.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ marginBottom: "15px" }}>
      <Section
          title="SUM"
          statistics={{
            projectId,
            sum: sumsData["0sum"],
            plan1sum: sumsData["1sum"],
            plan2sum: sumsData["2sum"],
            plan3sum: sumsData["3sum"],
          }}
          langData={langData}
        />
      </div>
  
<div style={{ height: "300px", overflowY: "auto", marginBottom: "10px" }}>
{Object.keys(plansDescription).map((key) => (
  <PlanDescription
    key={key}
    title={`Plan${key}`}
    name={key === "1" ? langData.plan1 : key === "2" ? langData.plan2 : langData.plan3}
    description={plansDescription[key]}
    isExpanded={expandedPlan === `Plan${key}`}
    onExpand={handlePlanExpand}
  />
))}

<MapLegend />
</div>

{isChartsDialogOpen && (
<ChartsDialog onClose={() => setIsChartsDialogOpen(false)} />
)}
</div>
  );
};

export default Stats;
