// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProjectList.css */

/* Style the scrollbar track */
.custom-scrollbar {
    overflow-y: auto;
    max-height: calc(100vh - 270px); /* Adjust 300px or as needed */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent; /* For Firefox */
  }
  
  /* Style the scrollbar thumb (Chrome, Edge, Safari) */
  .custom-scrollbar::-webkit-scrollbar {
    width: 4px; /* Adjust the width as needed */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Adjust the color as needed */
    border-radius: 4px; /* Adjust the border-radius as needed */
  }
  
  /* Hide the scrollbar track in Safari */
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Project/ProjectList.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB,8BAA8B;AAC9B;IACI,gBAAgB;IAChB,+BAA+B,EAAE,8BAA8B;IAC/D,qBAAqB,EAAE,gBAAgB;IACvC,wCAAwC,EAAE,gBAAgB;EAC5D;;EAEA,qDAAqD;EACrD;IACE,UAAU,EAAE,+BAA+B;EAC7C;;EAEA;IACE,sBAAsB,EAAE,+BAA+B;IACvD,kBAAkB,EAAE,uCAAuC;EAC7D;;EAEA,uCAAuC;EACvC;IACE,6BAA6B;EAC/B","sourcesContent":["/* ProjectList.css */\r\n\r\n/* Style the scrollbar track */\r\n.custom-scrollbar {\r\n    overflow-y: auto;\r\n    max-height: calc(100vh - 270px); /* Adjust 300px or as needed */\r\n    scrollbar-width: thin; /* For Firefox */\r\n    scrollbar-color: transparent transparent; /* For Firefox */\r\n  }\r\n  \r\n  /* Style the scrollbar thumb (Chrome, Edge, Safari) */\r\n  .custom-scrollbar::-webkit-scrollbar {\r\n    width: 4px; /* Adjust the width as needed */\r\n  }\r\n  \r\n  .custom-scrollbar::-webkit-scrollbar-thumb {\r\n    background-color: #ccc; /* Adjust the color as needed */\r\n    border-radius: 4px; /* Adjust the border-radius as needed */\r\n  }\r\n  \r\n  /* Hide the scrollbar track in Safari */\r\n  .custom-scrollbar::-webkit-scrollbar-track {\r\n    background-color: transparent;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
