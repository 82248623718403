import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const ChartsDialog = ({ onClose }) => {
  // Use useSelector to access sum values from the Redux store
  const aoisum = useSelector((state) => state.polydata.aoisum);
  const plan1sum = useSelector((state) => state.polydata.plan1sum);
  const plan2sum = useSelector((state) => state.polydata.plan2sum);
  const plan3sum = useSelector((state) => state.polydata.plan3sum);

  const getChartData = () =>
    [
      { name: "AOI", value: aoisum, fill: "#8884d8" },
      { name: "Plan 1", value: plan1sum, fill: "#82ca9d" },
      { name: "Plan 2", value: plan2sum, fill: "#ffc658" },
      { name: "Plan 3", value: plan3sum, fill: "#ff7300" },
    ].map((entry) => ({ ...entry, fill: entry.fill.toString() }));

  const chartData = getChartData();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "rgba(255, 255, 255, 0.8)",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <p style={{ margin: "0" }}>SUM: {payload[0].value}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "10px",
          }}
        >
          {/* Move the button to the right */}
          <div style={{ flexGrow: 1 }}></div>
          <IconButton onClick={onClose} color="primary" size="small">
            <CloseIcon />
          </IconButton>
        </div>

        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Sum Chart</h2>

        {/* Center the BarChart and adjust its width */}
        <BarChart
          width={800}
          height={400}
          layout="vertical"
          data={chartData}
          style={{ margin: "auto" }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="value" fill="fill" />
        </BarChart>
      </div>
    </Dialog>
  );
};

export default ChartsDialog;
